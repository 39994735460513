import React, { Component } from 'react';
import { connect } from 'react-redux';
import { contentGeneration } from '../../../store/actions';
import './ContentDashboard.css';
// import ContentDashboardTable from '../../../components/ContentDashboardTable/ContentDashboardTable';
import FormModal from '../../../components/FormModal/FormModal';
import ContentProjectForm from '../../../components/ContentProjectForm/ContentProjectForm';
import PlanningMonthForm from '../../../components/PlanningMonthForm/PlanningMonthForm';
import { Button, Icon, Grid, Loader } from 'semantic-ui-react';
import ArticleHistoryChart from '../../../components/ArticleHistoryChart/ArticleHistoryChart';

export class Dashboard extends Component {
	componentDidMount() {
		this.props.fetchContentStatuses();
		this.props.articleHistorySets && !this.props.articleHistorySets[0] && this.props.fetchArticleHistorySets(2);
	}

	viewMonth = (year, month) => {
		this.props.history.push(`${this.props.match.path}articles/${year}/${month}`);
	};

	viewProject = (projectID) => {
		this.props.history.push(`${this.props.match.path}projects/${projectID}`);
	};

	render() {
		const { articleHistorySets, isLoadingArticleHistorySets, didLoadArticleHistorySets } = this.props;
		// const slides = [
		// 	{
		// 		key: 1,
		// 		component: (
		// 			<div className="CarouselSlide">
		// 				<Grid columns="equal">
		// 					<Grid.Row>
		// 						<Grid.Column>
		// 							<ArticleHistoryChart targetHistoryStatus={3} historyData={articleHistorySets} />
		// 						</Grid.Column>
		// 						<Grid.Column>
		// 							<ArticleHistoryChart targetHistoryStatus={5} historyData={articleHistorySets} />
		// 						</Grid.Column>
		// 						<Grid.Column>
		// 							<ArticleHistoryChart targetHistoryStatus={7} historyData={articleHistorySets} />
		// 						</Grid.Column>
		// 					</Grid.Row>
		// 				</Grid>
		// 			</div>
		// 		)
		// 	},
		// 	{
		// 		key: 2,
		// 		component: (
		// 			<div className="CarouselSlide">
		// 				<Grid columns="equal">
		// 					<Grid.Row>
		// 						<Grid.Column>
		// 							<ArticleHistoryChart targetHistoryStatus={4} historyData={articleHistorySets} />
		// 						</Grid.Column>
		// 						<Grid.Column>
		// 							<ArticleHistoryChart targetHistoryStatus={6} historyData={articleHistorySets} />
		// 						</Grid.Column>
		// 						<Grid.Column>
		// 							<ArticleHistoryChart targetHistoryStatus={8} historyData={articleHistorySets} />
		// 						</Grid.Column>
		// 					</Grid.Row>
		// 				</Grid>
		// 			</div>
		// 		)
		// 	}
		// ];

		return (
			<div className="ContentDashboard">
				{/* <div className="ContentDashboard--articleHistoryCharts">
					{!isLoadingArticleHistorySets && didLoadArticleHistorySets ? (
						articleHistorySets &&
						articleHistorySets[0] && <Carousel basicControls inlineControls items={slides} reversed />
					) : (
						<div className="ContentDashboard--articleHistoryChartsLoader">
							<Loader inline='centered' active>Loading Article History</Loader>
						</div>
					)}
				</div> */}

				<div className="ContentDashboard--articleHistoryCharts">
					{!isLoadingArticleHistorySets && didLoadArticleHistorySets ? (
						articleHistorySets &&
						articleHistorySets[0] && <div className="ContentDashboard--articleHistoryChartsContainer">
							<Grid columns="equal">
							<Grid.Row>
								<Grid.Column width={4}>
									<ArticleHistoryChart targetHistoryStatus='writing' historyData={articleHistorySets} />
								</Grid.Column>
								<Grid.Column width={4}>
									<ArticleHistoryChart targetHistoryStatus='editing' historyData={articleHistorySets} />
								</Grid.Column>
								<Grid.Column width={4}>
									<ArticleHistoryChart targetHistoryStatus='rewrite' historyData={articleHistorySets} />
								</Grid.Column>
								<Grid.Column width={4}>
									<ArticleHistoryChart targetHistoryStatus='reedit' historyData={articleHistorySets} />
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column width={4}>
									<ArticleHistoryChart targetHistoryStatus='internal_review' historyData={articleHistorySets} />
								</Grid.Column>
								<Grid.Column width={4}>
									<ArticleHistoryChart targetHistoryStatus='final_review' historyData={articleHistorySets} />
								</Grid.Column>
								<Grid.Column width={4}>
									<ArticleHistoryChart targetHistoryStatus='ready_to_post' historyData={articleHistorySets} />
								</Grid.Column>
								<Grid.Column width={4}>
									<ArticleHistoryChart targetHistoryStatus='post_qa' historyData={articleHistorySets} />
								</Grid.Column>
							</Grid.Row>
						</Grid>
						</div>
					) : (
						<div className="ContentDashboard--articleHistoryChartsLoader">
							<Loader inline='centered' active>Loading Article History</Loader>
						</div>
					)}
				</div>

				{/* <ContentDashboardTable
					headerText="Planning Months"
					headerContent={
						<FormModal
							headerText="Add Planning Month"
							headerIcon="calendar"
							actionTrigger={
								<Button icon labelPosition="left" basic>
									<Icon name="calendar" /> Add Planning Month
								</Button>
							}
						>
							<PlanningMonthForm {...this.props} />
						</FormModal>
					}
					handleAddingContent={this.props.handleAddingContent}
					isAddingContent={this.props.isAddingContent}
					viewMonth={this.viewMonth}
					statuses={this.props.contentStatuses}
					data={this.props.planningMonths}
					isLoading={this.props.isLoadingArticles}
				/>
				<ContentDashboardTable
					headerText="Content Projects"
					headerContent={
						<FormModal
							headerText="Add Content Project"
							headerIcon="paperclip"
							actionTrigger={
								<Button icon labelPosition="left" basic>
									<Icon name="paperclip" /> Add Content Project
								</Button>
							}
						>
							<ContentProjectForm {...this.props} />
						</FormModal>
					}
					handleAddingContent={this.props.handleAddingContent}
					isAddingContent={this.props.isAddingContent}
					viewProject={this.viewProject}
					statuses={this.props.contentStatuses}
					data={this.props.contentProjects}
					isLoading={this.props.isLoadingProjects}
				/> */}
				<div className="ContentDashboard--actions">
				<FormModal
					headerText="Add Content Project"
					headerIcon="paperclip"
					actionTrigger={
						<Button icon labelPosition="left" basic>
							<Icon name="paperclip" /> Add Content Project
						</Button>
					}
				>
					<ContentProjectForm {...this.props} />
				</FormModal>
				<FormModal
							headerText="Add Planning Month"
							headerIcon="calendar"
							actionTrigger={
								<Button icon labelPosition="left" basic>
									<Icon name="calendar" /> Add Planning Month
								</Button>
							}
						>
							<PlanningMonthForm {...this.props} />
						</FormModal>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		planningMonths: state.contentGeneration.planningMonths,
		contentProjects: state.contentGeneration.contentProjects,
		contentStatuses: state.contentGeneration.contentStatuses,
		isLoadingMonths: state.contentGeneration.isLoadingMonths,
		isLoadingProjects: state.contentGeneration.isLoadingProjects,
		isLoadingArticles: state.contentGeneration.isLoadingArticles,
		articleHistorySets: state.contentGeneration.articleHistorySets.data,
		isLoadingArticleHistorySets: state.contentGeneration.articleHistorySets.isLoading,
		didLoadArticleHistorySets: state.contentGeneration.articleHistorySets.isLoaded
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchPlanningMonths: () => {
			return dispatch(contentGeneration.fetchPlanningMonths());
		},
		fetchContentStatuses: () => {
			return dispatch(contentGeneration.fetchContentStatuses());
		},
		fetchArticleHistorySets: (weeksBack) => dispatch(contentGeneration.fetchArticleHistorySets(weeksBack)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
