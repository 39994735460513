import React, { Component } from "react";
import { connect } from "react-redux";
import { contentGeneration, organizations } from "../../../store/actions";
import "./ArticleList.css";
import SortableTable from "../../../components/SortableTable/SortableTable";
import ActionHeader from "../../../components/ActionHeader/ActionHeader";
import { Loader, Header } from "semantic-ui-react";
import { parseSearchParams, toTitleCase } from "../../../utility";

export class ArticleList extends Component {
  componentDidMount() {
    if (this.getSearchParams()) {
      this.props.fetchArticles(this.getSearchParams());
      this.props.writers && !this.props.writers[0] && this.props.fetchWriters();
      this.props.organizations &&
        !this.props.organizations[0] &&
        this.props.fetchOrganizations();
      this.props.comments &&
        !this.props.comments[0] &&
        this.props.fetchContentComments();
      this.props.contentStatuses &&
        !this.props.contentStatuses[0] &&
        this.props.fetchContentStatuses();
      this.props.contentStaff &&
        !this.props.contentStaff[0] &&
        this.props.fetchContentStaff();
      this.props.contentTypes &&
        !this.props.contentTypes[0] &&
        this.props.fetchContentTypes();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (!prevProps.location.search && this.props.location.search) ||
      (prevProps.location.search &&
        prevProps.location.search !== this.props.location.search)
    ) {
      this.props.fetchArticles(this.getSearchParams());
    }
  }

  getSearchParams = () => {
    const searchParams = this.props.location.search;
    if (searchParams) {
      const params = new URLSearchParams(searchParams);
      if (params) {
        let output = {};
        for (const [key, value] of params.entries()) {
          output[key] = value;
        }
        return output;
      } else {
        return null;
      }
    }
  };

  renderPageTitle = () => {
    if (this.getSearchParams()) {
      const contentStatus = Object.entries(this.getSearchParams()).find(
        ([k, v]) => k === "contentStatus"
      );
      if (contentStatus) {
        const correspondingStatus =
          this.props.contentStatuses &&
          this.props.contentStatuses[0] &&
          this.props.contentStatuses.find(
            (status) => status.uid === contentStatus[1]
          );
        if (correspondingStatus) {
          return `- ${correspondingStatus.name}`;
        }
      } else {
        const pageTitleSuffixKey =
          Object.entries(this.getSearchParams())[0] &&
          Object.entries(this.getSearchParams())[0][0];
        const pageTitleSuffixValue =
          Object.entries(this.getSearchParams())[0] &&
          Object.entries(this.getSearchParams())[0][1];
        if (pageTitleSuffixKey && pageTitleSuffixValue) {
          return `- ${toTitleCase(
            pageTitleSuffixKey
          )}: ${pageTitleSuffixValue}`;
        } else {
          return "";
        }
      }
    }
    return null;
  };

  getContentStatus = () => {
    if (this.getSearchParams()) {
      const contentStatus = Object.entries(this.getSearchParams()).find(
        ([k, v]) => k === "contentStatus"
      );
      if (contentStatus) {
        return contentStatus[1]
      } else {
        return null
      }
    }
  }

  handleParamSort = () => {
    const sortParams =
      this.props.location.search &&
      parseSearchParams(this.props.location.search, "sortBy");
    if (sortParams) {
      console.log("sortParams are: ", sortParams);
      // if (this.props.articles && this.props.articles[0]) {

      // }
    }
  };

  renderDueDate = (article) => {
    switch (article.status.uid) {
      case 'writing':
        return article.duedate_write;
      case 'rewrite':
        return article.duedate_rewrite;
      case 'editing':
        return article.duedate_edit;
      case 'reedit':
        return article.duedate_reedit;
      case 'final_review':
        return article.duedate_finalreview;
      case 'ready_to_post':
        return article.duedate_schedulepost;
      case 'post_qa':
        return article.duedate_qapost;
      case 'scheduled':
        return article.duedate_golive;
      case 'posted':
        return article.duedate_golive;
      default:
        return null;
    }
  };

  renderCorrespondingDueDateType = (statusUid) => {
    switch (statusUid) {
      case 'writing':
        return "duedate_write";
      case 'rewrite':
        return "duedate_rewrite";
      case 'editing':
        return "duedate_edit";
      case 'reedit':
        return "duedate_reedit";
      case 'final_review':
        return "duedate_finalreview";
      case 'ready_to_post':
        return "duedate_schedulepost";
      case 'post_qa':
        return "duedate_qapost";
      case 'scheduled':
        return "duedate_golive";
      case 'posted':
        return "duedate_golive";
      default:
        return null;
    }
  };

  render() {
    return (
      <div className="ArticleList">
        <ActionHeader headerText={`Articles ${this.renderPageTitle()}`} />
        {this.getSearchParams() ? (
          <div className="ArticleList--list">
            {!this.props.isLoading && this.props.isLoaded ? (
              this.props.articles && this.props.articles[0] ? (
                <SortableTable
                  selectedArticles={this.props.selectedArticles}
                  handleMultiSelect={this.props.handleMultiSelect}
                  selectedItem={this.props.articleToEdit}
                  contentStaff={this.props.contentStaff}
                  contentTypes={this.props.contentTypes}
                  writers={this.props.writers}
                  organizations={this.props.organizations}
                  contentStatuses={this.props.contentStatuses}
                  handleSelect={this.props.selectArticle}
                  multiSelectEnabled
                  originalArticles={this.props.articles}
                  tableData={this.props.articles}
                  isArticleList
                  // isStatusParam={
                  //   this.getSearchParams() &&
                  //   Number(this.getSearchParams()["contentStatus"]) &&
                  //   Number.isNaN(this.getSearchParams()["contentStatus"])
                  //     ? null
                  //     : Number(this.getSearchParams()["contentStatus"])
                  // }
                  // isStatusParam={
                  //   this.getSearchParams() &&
                  //   this.getSearchParams()["contentStatus"]
                  //     ? Number.isNaN(this.getSearchParams()["contentStatus"])
                  //       ? null
                  //       : Number(this.getSearchParams()["contentStatus"])
                  //     : null
                  // }
                  isStatusParam={
                    this.getSearchParams() &&
                    this.getSearchParams()["contentStatus"] ? this.getSearchParams()["contentStatus"] : null
                  }
                  renderDueDate={this.renderDueDate}
                  renderCorrespondingDueDateType={
                    this.renderCorrespondingDueDateType
                  }
                />
              ) : (
                <div className="ArticleList--noData">
                  <Header as="h4">No Articles Found</Header>
                </div>
              )
            ) : (
              <Loader size="large" active>
                Loading Articles
              </Loader>
            )}
          </div>
        ) : (
          <div className="ArticleList--noParameters">
            <Header as="h4">
              Please provide query parameters to view a list
            </Header>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    articles: state.contentGeneration.contentArticles,
    isLoading: state.contentGeneration.isLoadingArticles,
    isLoaded: state.contentGeneration.articlesLoaded,
    errors: state.contentGeneration.errors,
    contentStatuses: state.contentGeneration.contentStatuses,
    isAddingArticle: state.contentGeneration.isAddingArticle,
    comments: state.contentGeneration.contentComments,
    contentStaff: state.contentGeneration.contentStaff,
    writers: state.contentGeneration.writers,
    organizations: state.organizations.organizations,
    contentTypes: state.contentGeneration.contentTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchArticles: (parameters) =>
      dispatch(contentGeneration.fetchArticles(parameters)),
    fetchContentStatuses: () => {
      return dispatch(contentGeneration.fetchContentStatuses());
    },
    fetchContentComments: () => {
      return dispatch(contentGeneration.fetchContentComments());
    },
    fetchWriters: () => {
      return dispatch(contentGeneration.fetchWriters());
    },
    fetchOrganizations: () => {
      return dispatch(organizations.fetchOrganizations());
    },
    fetchContentStaff: () => dispatch(contentGeneration.fetchContentStaff()),
    fetchContentTypes: () => {
      return dispatch(contentGeneration.fetchContentTypes());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticleList);
