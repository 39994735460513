import React, { Component } from "react";
import { contentGeneration, organizations } from "../../store/actions";
import { connect } from "react-redux";
import {
  Form,
  Input,
  Select,
  Button,
  TextArea,
  Icon,
  Transition,
} from "semantic-ui-react";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import FormHeader from "../../components/FormHeader/FormHeader";
import "./ContentArticleForm.css";
import ErrorHandler from "../ErrorHandler/ErrorHandler";
import { Loader } from "semantic-ui-react";
import { DateInput, DateTimeInput } from "semantic-ui-calendar-react";
import CommentDetail from "../CommentDetail/CommentDetail";
import CommentForm from "../CommentForm/CommentForm";
import FormMessage from "../FormMessage/FormMessage";
import UpdateIndicator from "../UpdateIndicator/UpdateIndicator";
import _ from "lodash";
import BasicModal from "../BasicModal/BasicModal";
import KeywordForm from "../KeywordForm/KeywordForm";
import { getUniqueByKey } from "../../utility";
import DividerHeader from "../DividerHeader/DividerHeader";
import FeedbackDetail from "../FeedbackDetail/FeedbackDetail";
import ArticleChangelog from "../ArticleChangelog/ArticleChangelog";

const initialFormState = {
  title: "",
  wordCount: "",
  mileStone: "",
  liveUrl: "",
  admin_draft_url: "",
  dueDateGoLive: "",
  planningYearMonth: "",
  project: "",
  description: "",
  keywords: [],
  focus_keyword_plaintext: "",
  client: "",
  status: "",
  contentType: "",
  // channels: [],
  primary_url: "",
  primary_anchor: "",
  secondary_url: "",
  secondary_anchor: "",
  lead: "",
  final_approver: "",
  editor: "",
  poster: "",
  writer: "",
  googleDoc: "",
  googleSheet: "",
  duedate_write: "",
  duedate_rewrite: "",
  duedate_edit: "",
  duedate_reedit: "",
  duedate_finalreview: "",
  duedate_schedulepost: "",
  duedate_qapost: "",
  duedate_golive: "",
  archived: "",
};

class ContentArticleForm extends Component {
  state = {
    form: initialFormState,
    showAdvanced: false,
    showComments: false,
    updating: false,
    preparingUpdate: false,
    readyForUpdate: false,
    didUpdate: false,
    dropdownUpdated: false,
    keywordModelOpen: false,
  };

  componentDidMount() {
    if (!this.props.contentTypes[0]) {
      this.fetchContentData();
    } else if (
      this.props.articleToEdit &&
      this.props.articleToEdit.client &&
      this.props.articleToEdit.client.id
    ) {
      this.props.fetchApprovalStaff(this.props.articleToEdit.client.id);
      // this.props.fetchContentKeywords(this.props.articleToEdit.client.id);
    }
    // if (this.state.form && this.state.form.client) {
    //   this.props.fetchContentKeywords(this.state.form.client);
    // }
    if (this.props.articleToEdit) {
      this.setArticleData();
      this.props.fetchFeedback(this.props.articleToEdit.id);
      // this.props.fetchArticleChangelog(this.props.articleToEdit.id)
    } else if (this.props.templateToEdit) {
      this.setTemplateData();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // only if we are using an update form, not a create form
    if (this.props.articleToEdit || this.props.templateToEdit) {
      // Take snapshot of old form if updating
      // if the onChange event is an input
      if (
        !this.state.preparingUpdate &&
        !this.state.readyForUpdate &&
        this.state.updating !== prevState.updating
      ) {
        this.setState({ oldForm: prevState.form });
      } else if (
        this.state.dropdownUpdated &&
        this.state.readyForUpdate &&
        !_.isEqual(this.state.oldForm, this.state.form)
      ) {
        // else if the onChange is a dropdown
        this.setState({ oldForm: prevState.form });
      }
      // if there is an existing snapshot, the form is not being updated and it is ready for updates
      if (
        this.state.oldForm &&
        !this.state.updating &&
        this.state.readyForUpdate &&
        !this.state.preparingUpdate &&
        !this.state.didUpdate
      ) {
        // check to make sure the old form is not the same as the new changes
        if (
          this.state.oldForm &&
          !_.isEqual(this.state.oldForm, this.state.form) &&
          this.state.readyForUpdate &&
          !this.state.preparingUpdate &&
          !this.state.didUpdate &&
          !this.state.updating
        ) {
          this.handleSubmit();
          this.setState({
            readyForUpdate: false,
            didUpdate: true,
            preparingUpdate: false,
            dropdownUpdated: false,
            updating: false,
            oldForm: null,
          });
          // else if there weren't any changes do not make an update
        } else if (_.isEqual(this.state.oldForm, this.state.form)) {
          this.setState({ updating: false, oldForm: null });
        }
      }
    }
    if (
      !prevProps.articleToEdit &&
      this.props.articleToEdit &&
      this.props.articleToEdit.client &&
      this.props.articleToEdit.client.id
    ) {
      this.props.fetchApprovalStaff(this.props.articleToEdit.client.id);
      // this.props.fetchContentKeywords(this.props.articleToEdit.client.id);
    }
    if (
      !prevProps.templateToEdit &&
      this.props.templateToEdit &&
      this.props.templateToEdit.client
    ) {
      this.props.fetchApprovalStaff(this.props.templateToEdit.client.id);
    }
    if (
      this.props.articleToEdit !== prevProps.articleToEdit ||
      this.props.templateToEdit !== prevProps.templateToEdit
    ) {
      if (
        this.props.articleToEdit &&
        prevProps.articleToEdit &&
        prevProps.articleToEdit.client.id !== this.props.articleToEdit.client.id
      ) {
        this.props.fetchApprovalStaff(this.props.articleToEdit.client.id);
        // this.props.fetchContentKeywords(this.props.articleToEdit.client.id);
      } else if (
        this.props.templateToEdit &&
        prevProps.templateToEdit &&
        prevProps.templateToEdit.client.id !==
          this.props.templateToEdit.client.id
      ) {
        this.props.fetchApprovalStaff(this.props.templateToEdit.client.id);
      }

      if (this.props.articleToEdit) {
        this.setArticleData();
      } else if (this.props.templateToEdit) {
        this.setTemplateData();
      } else {
        this.clearForm();
      }
    }
    if (this.props.addingTemplate || this.props.addingContent) {
      if (this.state.form.client !== prevState.form.client) {
        let currentOrg = this.props.organizations.find(
          (org) => org.id === this.state.form.client
        );
        if (currentOrg) {
          this.props.fetchApprovalStaff(currentOrg.id);
          // if (this.props.addingContent) {
          //   this.props.fetchContentKeywords(currentOrg.id);
          // }
        }
      }
    }
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.props.closeForm();
    }
    if (
      this.props.articleToEdit !== prevProps.articleToEdit ||
      this.props.templateToEdit !== prevProps.templateToEdit
    ) {
      this.setState({ oldForm: null });
      if (this.props.articleToEdit) {
        this.props.fetchFeedback(this.props.articleToEdit.id);
        this.props.fetchArticleChangelog(this.props.articleToEdit.id);
      }
    }
    if (
      this.props.newestKeyword !== prevProps.newestKeyword &&
      this.props.newestKeyword
    ) {
      this.setState({
        form: {
          ...this.state.form,
          keywords: this.state.form.keywords.concat(
            this.props.newestKeyword.id
          ),
        },
      });
    }
  }

  setArticleData = () => {
    this.setState({
      form: {
        id: this.props.articleToEdit.id,
        title: this.props.articleToEdit.title,
        wordCount: this.props.articleToEdit.min_word_count,
        mileStone: this.props.articleToEdit.milestone,
        liveUrl: this.props.articleToEdit.live_url,
        admin_draft_url: this.props.articleToEdit.admin_draft_url,
        planningYearMonth: this.props.articleToEdit.planning_year_month
          ? this.props.articleToEdit.planning_year_month.id
          : "",
        project: this.props.articleToEdit.project
          ? this.props.articleToEdit.project.id
          : "",
        description: this.props.articleToEdit.description,
        keywords: this.props.articleToEdit.keywords,
        focus_keyword_plaintext:
          this.props.articleToEdit.focus_keyword_plaintext,
        client: this.props.articleToEdit.client.id,
        primary_url: this.props.articleToEdit.url_1,
        primary_anchor: this.props.articleToEdit.anchor_1,
        secondary_url: this.props.articleToEdit.url_2,
        secondary_anchor: this.props.articleToEdit.anchor_2,
        contentType: this.props.articleToEdit.content_type.id,
        // channels: this.props.articleToEdit.channels,
        final_approver: this.props.articleToEdit.final_approver.id,
        lead: this.props.articleToEdit.lead.id,
        editor: this.props.articleToEdit.editor.id,
        writer: this.props.articleToEdit.writer.id,
        poster: this.props.articleToEdit.poster.id,
        googleDoc: this.props.articleToEdit.google_doc,
        googleSheet: this.props.articleToEdit.google_sheet,
        status: this.props.articleToEdit.status.id,
        duedate_write: this.props.articleToEdit.duedate_write,
        duedate_rewrite: this.props.articleToEdit.duedate_rewrite,
        duedate_edit: this.props.articleToEdit.duedate_edit,
        duedate_reedit: this.props.articleToEdit.duedate_reedit,
        duedate_finalreview: this.props.articleToEdit.duedate_finalreview,
        duedate_qapost: this.props.articleToEdit.duedate_qapost,
        duedate_schedulepost: this.props.articleToEdit.duedate_schedulepost,
        duedate_golive: this.props.articleToEdit.duedate_golive,
        archived: this.props.articleToEdit.archived,
      },
    });
  };

  setTemplateData = () => {
    this.setState({
      form: {
        id: this.props.templateToEdit.id,
        title: this.props.templateToEdit.title,
        wordCount: this.props.templateToEdit.min_word_count,
        mileStone: this.props.templateToEdit.milestone,
        client: this.props.templateToEdit.client.id,
        contentType: this.props.templateToEdit.content_type.id,
        // channels: this.props.templateToEdit.content_channels,
        lead: this.props.templateToEdit.lead.id,
        poster: this.props.templateToEdit.poster.id,
        final_approver: this.props.templateToEdit.final_approver.id,
        editor: this.props.templateToEdit.editor.id,
        writer: this.props.templateToEdit.writer.id,
        archived: this.props.templateToEdit.archived,
      },
    });
  };

  fetchContentData = () => {
    console.log("fetching initial data here");
    !this.props.contentTypes[0] && this.props.fetchContentTypes();
    !this.props.contentStatuses[0] && this.props.fetchContentStatuses();
    // !this.props.channels[0] && this.props.fetchContentChannels();
    !this.props.writers[0] && this.props.fetchWriters();
    !this.props.contentStaff[0] && this.props.fetchContentStaff();
    !this.props.organizations[0] && this.props.fetchOrganizations();
    !this.props.comments[0] && this.props.fetchContentComments();
    !this.props.planningMonths[0] && this.props.fetchPlanningMonths();
    if (this.props.articleToEdit) {
      this.props.fetchApprovalStaff(this.props.articleToEdit.client.id);
      // this.props.fetchContentKeywords(this.props.articleToEdit.client.id);
    } else if (this.props.templateToEdit) {
      this.props.fetchApprovalStaff(this.props.templateToEdit.client.id);
    }
  };

  clearForm = () => {
    this.setState({ form: initialFormState });
  };

  toggleKeywordModal = (open) => {
    this.setState({ keywordModelOpen: open });
  };

  // showApprovalStaff = () => {
  //   if (this.props.articleToEdit) {
  //     if (this.props.articleToEdit.client.organization_requirements) {
  //       if (
  //         this.props.articleToEdit.client.organization_requirements
  //           .needs_approval
  //       ) {
  //         return true;
  //       } else {
  //         return false;
  //       }
  //     }
  //   } else if (this.props.templateToEdit) {
  //     if (this.props.templateToEdit.client.organization_requirements) {
  //       if (
  //         this.props.templateToEdit.client.organization_requirements
  //           .needs_approval
  //       ) {
  //         return true;
  //       } else {
  //         return false;
  //       }
  //     }
  //   } else if (this.props.addingContent || this.props.addingTemplate) {
  //     let currentOrg = this.props.organizations.find(
  //       org => org.id === this.state.form.client
  //     );
  //     if (
  //       currentOrg &&
  //       currentOrg.organization_requirements &&
  //       currentOrg.organization_requirements.needs_approval
  //     ) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   }
  // };

  handleChange(e, isUpdating) {
    if (this.props.articleToEdit || this.props.templateToEdit) {
      this.setState({
        form: {
          ...this.state.form,
          [e.target.name]: e.target.value,
        },
        updating: true,
        preparingUpdate: false,
        readyForUpdate: false,
        didUpdate: false,
      });
      if (!isUpdating) {
        setTimeout(() => {
          if (
            !this.state.preparingUpdate &&
            !this.state.readyForUpdate &&
            this.state.updating &&
            !this.state.didUpdate
          ) {
            this.setState({
              ...this.state,
              preparingUpdate: true,
              updating: false,
              readyForUpdate: false,
            });
            setTimeout(() => {
              if (
                this.state.preparingUpdate &&
                !this.state.readyForUpdate &&
                !this.state.updating &&
                !this.state.didUpdate
              ) {
                this.setState({
                  ...this.state,
                  readyForUpdate: true,
                  preparingUpdate: false,
                });
              } else if (!this.state.didUpdate && !this.state.preparingUpdate) {
                this.setState({
                  ...this.state,
                  updating: true,
                  readyForUpdate: false,
                  preparingUpdate: false,
                });
              }
            }, 1000);
          } else if (!this.state.didUpdate && !this.state.preparingUpdate) {
            this.setState({
              ...this.state,
              preparingUpdate: false,
              updating: true,
              readyForUpdate: false,
            });
          }
        }, 2000);
      }
    } else {
      this.setState({
        form: {
          ...this.state.form,
          [e.target.name]: e.target.value,
        },
      });
    }
  }

  handleSelectChange = async (event, { name, value }) => {
    if (this.state.form[name] !== value) {
      if (this.props.isAddingArticle || this.props.isAddingTemplate) {
        await this.setState({
          form: { ...this.state.form, [name]: value },
          readyForUpdate: false,
          dropdownUpdated: false,
          didUpdate: false,
        });
      } else {
        await this.setState({
          form: { ...this.state.form, [name]: value },
          readyForUpdate: true,
          dropdownUpdated: true,
          didUpdate: false,
        });
      }
    }
  };

  handleSubmit = (e) => {
    e && e.preventDefault();
    if (!this.props.articleToEdit && !this.props.templateToEdit) {
      if (this.props.addingContent) {
        if (!this.props.planningYearMonth) {
          this.props
            .addContentArticle(this.state.form, null, this.props.project.id)
            .then(() => {
              if (!this.props.errors) {
                this.clearForm();
                this.props.closeForm();
              }
            })
            .catch(() => {
              this.setState({ errors: "error" });
            });
        } else {
          this.props
            .addContentArticle(
              this.state.form,
              this.props.planningYearMonth.id,
              null
            )
            .then(() => {
              if (!this.props.errors) {
                this.clearForm();
                this.props.closeForm();
              }
            })
            .catch(() => {
              this.setState({ errors: "error" });
            });
        }
      } else if (this.props.addingTemplate) {
        this.props
          .addArticleTemplate(this.state.form)
          .then(() => {
            if (!this.props.errors) {
              this.clearForm();
              this.props.closeForm();
            }
          })
          .catch(() => {
            this.setState({ errors: "error" });
          });
      }
    } else {
      if (this.props.articleToEdit) {
        this.props.updateContentArticle(this.state.form);
      } else if (this.props.templateToEdit) {
        this.props.updateArticleTemplate(this.state.form);
      }
    }
  };

  shouldShow = (updateOnly) => {
    if (updateOnly) {
      if (this.props.articleToEdit) {
        return true;
      } else {
        return false;
      }
    } else {
      if (this.props.articleToEdit || this.props.addingContent) {
        return true;
      } else {
        return false;
      }
    }
  };

  handleArchive = async () => {
    if (this.props.articleToEdit) {
      await this.setState({ form: { ...this.state.form, archived: true } });
      this.props.updateContentArticle(this.state.form).then(() => {
        if (!this.props.errors) {
          this.clearForm();
          this.props.closeForm();
        }
      });
    } else if (this.props.templateToEdit) {
      await this.setState({ form: { ...this.state.form, archived: true } });
      this.props.updateArticleTemplate(this.state.form).then(() => {
        if (!this.props.errors) {
          this.clearForm();
          this.props.closeForm();
        }
      });
    }
  };

  handleGoogleDocSubmit = async (isSheet) => {
    const article = this.props.articleToEdit;
    if (this.props.project) {
      await this.props.createGoogleDoc(
        article.client.dba_name,
        this.props.project.start_year,
        this.props.project.start_month,
        article.title,
        article.id,
        this.props.project.id,
        isSheet ? isSheet : null
      );
    } else {
      await this.props.createGoogleDoc(
        article.client.dba_name,
        article.planning_year_month.year,
        article.planning_year_month.month,
        article.title,
        article.id,
        null,
        isSheet ? isSheet : null
      );
    }
    return this.setArticleData();
  };

  isStatus = (statusUid) => {
    const matchingStatus =
      this.props.contentStatuses &&
      this.props.contentStatuses.find((status) => status.uid === statusUid);
    return matchingStatus && matchingStatus.id === this.state.form.status
      ? true
      : false;
  };

  canEditDates = () => {
    if (this.props.addingContent || this.props.project) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const correspondingStaff = this.props.contentStaff &&
      this.props.contentStaff[0] && [
        ...this.props.contentStaff,
        ...this.props.approvalStaff,
      ];
    const uniqueCorrespondingStaff =
      correspondingStaff &&
      correspondingStaff[0] &&
      getUniqueByKey(correspondingStaff, "id");
    const clientOptions =
      uniqueCorrespondingStaff &&
      uniqueCorrespondingStaff[0] &&
      uniqueCorrespondingStaff.map((staff) => {
        return {
          key: staff.id,
          // text: (
          //   <span className="clientOption">
          //     {staff.name}{" "}
          //     {staff.is_staff && (
          //       <Label color="teal" size="mini" className="clientOption--label">
          //         Staff
          //       </Label>
          //     )}
          //   </span>
          // ),
          text: staff.name,
          value: staff.id,
        };
      });

    const articleComments =
      this.shouldShow() && !this.props.addingContent
        ? this.props.comments.filter(
            (comment) => comment.article === this.props.articleToEdit.id
          )
        : null;

    const articleFeedback =
      this.props.articleToEdit &&
      this.props.feedback &&
      this.props.feedback
        .filter((feedback) => feedback.article === this.props.articleToEdit.id)
        .filter((feedback) => feedback.given_by);

    return (
      this.props.contentStatuses && (
        <div className="ContentArticleForm">
          {this.state.form.client && (
            <BasicModal
              inverted
              isOpen={this.state.keywordModelOpen}
              handleClose={() => this.toggleKeywordModal(false)}
              title="Add Keyword"
              size="tiny"
              icon="searchengin"
            >
              <KeywordForm
                embeded
                onSuccess={() => this.toggleKeywordModal(false)}
                currentMonth={
                  this.props.planningYearMonth
                    ? this.props.planningYearMonth
                    : null
                }
                orgId={this.state.form.client}
                addingKeyword={true}
              />
            </BasicModal>
          )}
          <Transition.Group animation="slide down" duration={500}>
            {this.props.formMessages[0] && (
              <FormMessage>{this.props.formMessages}</FormMessage>
            )}
          </Transition.Group>
          {(this.state.updating || this.state.preparingUpdate) && (
            <UpdateIndicator />
          )}
          <FormHeader headerText={this.props.handleTitle()}>
            {!this.shouldShow() && (
              <Button
                size="mini"
                type="button"
                compact
                color="teal"
                loading={this.props.approvalStaffLoading}
                onClick={() =>
                  this.props.fetchApprovalStaff(
                    this.props.articleToEdit
                      ? this.props.articleToEdit.client.id
                      : this.props.templateToEdit.client.id
                  )
                }
              >
                Refresh
              </Button>
            )}
            {this.props.handleArchiveShow() && (
              <ConfirmationModal
                handleConfirm={() => this.handleArchive()}
                headerText="Archive Item?"
                headerIcon="trash alternate"
                buttonText="Archive"
                buttonInverted
                color="red"
                compact
                buttonSize="mini"
                bodyText="Are you sure you want to archive this?"
              />
            )}
            {/* {this.shouldShow() &&
              this.props.articleToEdit &&
              (this.props.articleToEdit.google_doc ? (
                <a
                  href={this.props.articleToEdit.google_doc}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button size="mini" compact icon color="blue">
                    View Doc <Icon name="google" />
                  </Button>
                </a>
              ) : (
                <Button
                  onClick={() => this.handleGoogleDocSubmit()}
                  size="mini"
                  type="button"
                  compact
                  loading={this.props.isAddingDoc && !this.props.addedDoc}
                  icon
                  color="blue"
                >
                  Create Doc <Icon name="google" />
                </Button>
              ))} */}
            <Button
              icon
              compact
              size="mini"
              onClick={() => this.props.closeForm()}
            >
              <Icon name="close" />
            </Button>
          </FormHeader>
          {/* {this.state.form.keywords && this.state.form.keywords[0] && (
            <React.Fragment>
              <Header as="h5">Focus Keywords</Header>
              {this.state.form.keywords.map((kw) => {
                const keyword = this.props.keywords.find(
                  (keyword) => keyword.id === kw
                );
                if (keyword) {
                  return (
                    <KeywordItemMenu
                      key={keyword.id}
                      detailBox
                      parentKeyword={
                        keyword.keyword.parent_keyword &&
                        keyword.keyword.parent_keyword.name
                      }
                      keyword={keyword.keyword.name}
                      seoValue={keyword.seo_value}
                      businessValue={keyword.business_value}
                      difficulty={keyword.keyword.difficulty}
                      volume={keyword.keyword.volume}
                      costPerClick={keyword.keyword.cost_per_click}
                    />
                  );
                } else {
                  return null;
                }
              })}
            </React.Fragment>
          )} */}
          {this.props.errors && <ErrorHandler errors={this.props.errors} />}
          <Form
            autoComplete="false"
            className="ContentArticleForm--Form"
            loading={!this.props.contentStatuses[0]}
            size="tiny"
            onSubmit={(e) => this.handleSubmit(e)}
          >
            <Form.Field inline>
              <label>Client</label>
              <Select
                disabled={
                  this.props.articleToEdit && !this.props.user.is_staff
                    ? true
                    : false
                }
                selection
                required
                search
                onChange={this.handleSelectChange}
                name="client"
                options={this.props.organizations.map((org) => ({
                  key: org.id,
                  text: org.dba_name,
                  value: org.id,
                }))}
                value={this.state.form.client}
              />
            </Form.Field>
            {/* {this.shouldShow() && (
              <Form.Field inline>
                <label>Focus Keyword</label>
                <Form.Group className="inlineSelectButton" inline>
                  <Select
                    selection
                    loading={this.props.isLoadingKeywords}
                    className="KeywordDropDown"
                    multiple
                    closeOnChange={true}
                    disabled={this.state.form.client ? false : true}
                    placeholder="Choose Keyword"
                    onChange={this.handleSelectChange}
                    name="keywords"
                    options={
                      this.props.keywords[0]
                        ? this.props.planningYearMonth
                          ? this.props.keywords
                              .filter(
                                (kw) =>
                                  kw.organization === this.state.form.client &&
                                  kw.planning_months.includes(
                                    this.props.planningYearMonth.id
                                  )
                              )
                              .map((kw) => ({
                                key: kw.id,
                                text: kw.keyword.name,
                                value: kw.id,
                                content: (
                                  <KeywordItemMenu
                                    key={kw.id}
                                    parentKeyword={
                                      kw.keyword.parent_keyword &&
                                      kw.keyword.parent_keyword.name
                                    }
                                    keyword={kw.keyword.name}
                                    seoValue={kw.seo_value}
                                    businessValue={kw.business_value}
                                    difficulty={kw.keyword.difficulty}
                                    volume={kw.keyword.volume}
                                    costPerClick={kw.keyword.cost_per_click}
                                  />
                                ),
                              }))
                          : this.props.keywords
                              .filter(
                                (kw) =>
                                  kw.organization === this.state.form.client
                              )
                              .map((kw) => ({
                                key: kw.id,
                                text: kw.keyword.name,
                                value: kw.id,
                                content: (
                                  <KeywordItemMenu
                                    parentKeyword={
                                      kw.keyword.parent_keyword &&
                                      kw.keyword.parent_keyword.name
                                    }
                                    keyword={kw.keyword.name}
                                    seoValue={kw.seo_value}
                                    businessValue={kw.business_value}
                                    difficulty={kw.keyword.difficulty}
                                    volume={kw.keyword.volume}
                                    costPerClick={kw.keyword.cost_per_click}
                                  />
                                ),
                              }))
                        : []
                    }
                    value={
                      this.state.form.keywords ? this.state.form.keywords : []
                    }
                  />
                  {this.shouldShow() && (
                    <Button
                      onClick={() => this.toggleKeywordModal(true)}
                      width={3}
                      type="button"
                      icon
                      size="small"
                    >
                      <Icon name="plus" />
                    </Button>
                  )}
                </Form.Group>
              </Form.Field>
            )} */}
            <Form.Field inline>
              <label>Title</label>
              <Input
                required
                placeholder="Title"
                onChange={(e) => this.handleChange(e, this.state.updating)}
                name="title"
                value={this.state.form.title}
              />
            </Form.Field>
            <Form.Field inline>
              <label>Type</label>
              <Select
                selection
                required
                search
                onChange={this.handleSelectChange}
                name="contentType"
                options={this.props.contentTypes.map((type) => ({
                  key: type.id,
                  text: type.name,
                  value: type.id,
                }))}
                value={this.state.form.contentType}
              />
            </Form.Field>
            {this.shouldShow(true) && (
              <Form.Field className="withAction" inline>
                <label>Google Doc</label>
                <Input
                  type="text"
                  // readOnly
                  readOnly={this.props.articleToEdit.google_doc}
                  placeholder="Google Doc URL"
                  onChange={(e) => this.handleChange(e, this.state.updating)}
                  name="googleDoc"
                  value={
                    this.state.form.googleDoc ? this.state.form.googleDoc : ""
                  }
                />

                <div className="inputAction">
                  {this.props.articleToEdit &&
                  !this.props.articleToEdit.google_doc ? (
                    <Button
                      onClick={() => this.handleGoogleDocSubmit()}
                      size="tiny"
                      type="button"
                      loading={this.props.isAddingDoc && !this.props.addedDoc}
                      icon
                      color="blue"
                    >
                      Create Doc <Icon name="google" />
                    </Button>
                  ) : (
                    <a
                      href={this.props.articleToEdit.google_doc}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button type="button" size="tiny" icon color="blue">
                        View Doc <Icon name="google" />
                      </Button>
                    </a>
                  )}
                </div>
              </Form.Field>
            )}
            {this.shouldShow(true) && (
              <Form.Field className="withAction" inline>
                <label>Google Sheet</label>
                <Input
                  type="text"
                  // readOnly
                  readOnly={this.props.articleToEdit.google_sheet}
                  placeholder="Google Sheet URL"
                  onChange={(e) => this.handleChange(e, this.state.updating)}
                  name="googleSheet"
                  value={
                    this.state.form.googleSheet ? this.state.form.googleSheet : ""
                  }
                />

                <div className="inputAction">
                  {this.props.articleToEdit &&
                  !this.props.articleToEdit.google_sheet ? (
                    <Button
                      onClick={() => this.handleGoogleDocSubmit(true)}
                      size="tiny"
                      type="button"
                      loading={this.props.isAddingDoc && !this.props.addedDoc}
                      icon
                      className="googleGreen"
                    >
                      Create Sheet <Icon name="google" />
                    </Button>
                  ) : (
                    <a
                      href={this.props.articleToEdit.google_sheet}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button type="button" size="tiny" icon className="googleGreen">
                        View Sheet <Icon name="google" />
                      </Button>
                    </a>
                  )}
                </div>
              </Form.Field>
            )}
            <Form.Field inline>
              <label>Word Count</label>
              <Input
                placeholder="Word Count"
                onChange={(e) => this.handleChange(e, this.state.updating)}
                name="wordCount"
                value={this.state.form.wordCount}
              />
            </Form.Field>
            <Form.Field inline>
              <label>Focus Keyword</label>
              <Input
                type="text"
                placeholder="Focus Keyword"
                onChange={(e) => this.handleChange(e, this.state.updating)}
                name="focus_keyword_plaintext"
                value={
                  this.state.form.focus_keyword_plaintext
                    ? this.state.form.focus_keyword_plaintext
                    : ""
                }
              />
            </Form.Field>
            {this.shouldShow() && (
              <Form.Field inline>
                <label>Description</label>
                <TextArea
                  name="description"
                  onChange={(e) => this.handleChange(e, this.state.updating)}
                  value={
                    this.state.form.description
                      ? this.state.form.description
                      : ""
                  }
                />
              </Form.Field>
            )}
            {(this.props.planningYearMonth ||
              this.props.templateToEdit ||
              this.props.isAddingTemplate) && (
              <Form.Field inline>
                <label>Milestone</label>
                <Select
                  selection
                  required
                  onChange={this.handleSelectChange}
                  name="mileStone"
                  options={[
                    {
                      key: 1,
                      text: 1,
                      value: 1,
                    },
                    {
                      key: 2,
                      text: 2,
                      value: 2,
                    },
                  ]}
                  value={this.state.form.mileStone}
                />
              </Form.Field>
            )}
            {this.shouldShow() && (
              <Form.Field inline>
                <label>Status</label>
                <Select
                  selection
                  search
                  required
                  onChange={this.handleSelectChange}
                  name="status"
                  options={this.props.contentStatuses
                    .filter((status) => status.is_active === true)
                    .map((status) => ({
                      key: status.id,
                      text: status.name,
                      value: status.id,
                    }))}
                  value={this.state.form.status}
                />
              </Form.Field>
            )}
            <DividerHeader
              className="ContentArticleForm--dueDateHeader"
              size="h5"
            >
              Assignees
            </DividerHeader>
            <Form.Field inline>
              <label>Writer</label>
              <Select
                clearable
                required
                search
                loading={this.props.writers && !this.props.writers[0]}
                onChange={this.handleSelectChange}
                name="writer"
                options={this.props.writers.map((writer) => ({
                  key: writer.id,
                  text: writer.user.name,
                  value: writer.id,
                }))}
                value={this.state.form.writer}
              />
            </Form.Field>
            <Form.Field inline>
              <label>Editor</label>
              <Select
                selection
                clearable
                search
                loading={this.props.approvalStaffLoading}
                onChange={this.handleSelectChange}
                name="editor"
                options={clientOptions && clientOptions[0] ? clientOptions : []}
                value={this.state.form.editor}
              />
            </Form.Field>
            <Form.Field inline>
              <label>Lead</label>
              <Select
                selection
                clearable
                search
                loading={this.props.approvalStaffLoading}
                onChange={this.handleSelectChange}
                name="lead"
                options={clientOptions && clientOptions[0] ? clientOptions : []}
                value={this.state.form.lead}
              />
            </Form.Field>
            <Form.Field inline>
              <label>Final Reviewer</label>
              <Select
                selection
                clearable
                search
                loading={this.props.approvalStaffLoading}
                onChange={this.handleSelectChange}
                name="final_approver"
                options={clientOptions && clientOptions[0] ? clientOptions : []}
                value={this.state.form.final_approver}
              />
            </Form.Field>
            <Form.Field inline>
              <label>Poster</label>
              <Select
                clearable
                selection
                search
                loading={this.props.approvalStaffLoading}
                onChange={this.handleSelectChange}
                name="poster"
                options={clientOptions && clientOptions[0] ? clientOptions : []}
                value={this.state.form.poster ? this.state.form.poster : ""}
              />
            </Form.Field>
            {/* <Form.Field inline>
              <label>Channels</label>
              <Select
                selection
                multiple
                required
                onChange={this.handleSelectChange}
                name="channels"
                options={this.props.channels.map((channel) => ({
                  key: channel.id,
                  text: channel.name,
                  value: channel.id,
                }))}
                value={this.state.form.channels}
              />
            </Form.Field> */}
            {this.shouldShow() && (
              <React.Fragment>
                <DividerHeader
                  className="ContentArticleForm--dueDateHeader"
                  size="h5"
                >
                  Due Dates
                </DividerHeader>
                <Form.Field inline>
                  <label>Write</label>
                  <DateInput
                    readOnly={!this.canEditDates()}
                    closable
                    name="duedate_write"
                    placeholder={`${
                      !this.canEditDates() ? "-" : "Choose Date"
                    }`}
                    iconPosition="left"
                    dateFormat="YYYY-MM-DD"
                    value={
                      this.state.form.duedate_write
                        ? this.state.form.duedate_write
                        : ""
                    }
                    onChange={this.handleSelectChange}
                  />
                </Form.Field>
                {this.isStatus("rewrite") && (
                  <Form.Field inline>
                    <label>Re-write</label>
                    <DateInput
                      readOnly={!this.canEditDates()}
                      closable
                      name="duedate_rewrite"
                      placeholder={`${
                        !this.canEditDates() ? "-" : "Choose Date"
                      }`}
                      iconPosition="left"
                      dateFormat="YYYY-MM-DD"
                      value={
                        this.state.form.duedate_rewrite
                          ? this.state.form.duedate_rewrite
                          : ""
                      }
                      onChange={this.handleSelectChange}
                    />
                  </Form.Field>
                )}
                <Form.Field inline>
                  <label>Edit</label>
                  <DateInput
                    readOnly={!this.canEditDates()}
                    closable
                    name="duedate_edit"
                    placeholder={`${
                      !this.canEditDates() ? "-" : "Choose Date"
                    }`}
                    iconPosition="left"
                    dateFormat="YYYY-MM-DD"
                    value={
                      this.state.form.duedate_edit
                        ? this.state.form.duedate_edit
                        : ""
                    }
                    onChange={this.handleSelectChange}
                  />
                </Form.Field>
                {this.isStatus("reedit") && (
                  <Form.Field inline>
                    <label>Re-Edit</label>
                    <DateInput
                      readOnly={!this.canEditDates()}
                      closable
                      name="duedate_reedit"
                      placeholder={`${
                        !this.canEditDates() ? "-" : "Choose Date"
                      }`}
                      iconPosition="left"
                      dateFormat="YYYY-MM-DD"
                      value={
                        this.state.form.duedate_reedit
                          ? this.state.form.duedate_reedit
                          : ""
                      }
                      onChange={this.handleSelectChange}
                    />
                  </Form.Field>
                )}
                <Form.Field inline>
                  <label>Final Review</label>
                  <DateInput
                    readOnly={!this.canEditDates()}
                    closable
                    name="duedate_finalreview"
                    placeholder={`${
                      !this.canEditDates() ? "-" : "Choose Date"
                    }`}
                    iconPosition="left"
                    dateFormat="YYYY-MM-DD"
                    value={
                      this.state.form.duedate_finalreview
                        ? this.state.form.duedate_finalreview
                        : ""
                    }
                    onChange={this.handleSelectChange}
                  />
                </Form.Field>
                <Form.Field inline>
                  <label>Schedule By</label>
                  <DateTimeInput
                    readOnly={!this.canEditDates()}
                    closable
                    disableMinute
                    timeFormat="AMPM"
                    name="duedate_schedulepost"
                    placeholder={`${
                      !this.canEditDates() ? "-" : "Choose Date"
                    }`}
                    iconPosition="left"
                    dateTimeFormat="YYYY-MM-DD hh:mm:ss"
                    value={
                      this.state.form.duedate_schedulepost
                        ? this.state.form.duedate_schedulepost
                        : ""
                    }
                    onChange={this.handleSelectChange}
                  />
                </Form.Field>
                <Form.Field inline>
                  <label>Post QA</label>
                  <DateInput
                    readOnly={!this.canEditDates()}
                    closable
                    name="duedate_qapost"
                    placeholder={`${
                      !this.canEditDates() ? "-" : "Choose Date"
                    }`}
                    iconPosition="left"
                    dateFormat="YYYY-MM-DD"
                    value={
                      this.state.form.duedate_qapost
                        ? this.state.form.duedate_qapost
                        : ""
                    }
                    onChange={this.handleSelectChange}
                  />
                </Form.Field>
                <Form.Field inline>
                  <label>Post</label>
                  <DateInput
                    readOnly={!this.canEditDates()}
                    closable
                    name="duedate_golive"
                    placeholder={`${
                      !this.canEditDates() ? "-" : "Choose Date"
                    }`}
                    iconPosition="left"
                    dateFormat="YYYY-MM-DD"
                    value={
                      this.state.form.duedate_golive
                        ? this.state.form.duedate_golive
                        : ""
                    }
                    onChange={this.handleSelectChange}
                  />
                </Form.Field>
              </React.Fragment>
            )}
            {articleFeedback && articleFeedback[0] && (
              <section className="articleFeedbackView">
                <DividerHeader size="h4" icon="comments" iconColor="grey">
                  Current Feedback
                </DividerHeader>
                {!this.props.isLoadingFeedback ? (
                  <div className="articleFeedbackView--container">
                    {articleFeedback.map((feedback) => (
                      <FeedbackDetail
                        key={feedback.id}
                        feedback={feedback}
                        user={this.props.user}
                      />
                    ))}
                  </div>
                ) : (
                  <Loader active size="small">
                    Loading Feedback
                  </Loader>
                )}
              </section>
            )}

            <Transition
              animation="slide down"
              duration={300}
              visible={this.state.showAdvanced}
            >
              <div className="AdvancedFields">
                {this.shouldShow() && (
                  <Form.Field inline>
                    <label>Live URL</label>
                    <Input
                      placeholder="Live URL"
                      onChange={(e) =>
                        this.handleChange(e, this.state.updating)
                      }
                      name="liveUrl"
                      value={
                        this.state.form.liveUrl ? this.state.form.liveUrl : ""
                      }
                    />
                  </Form.Field>
                )}
                {this.shouldShow() && (
                  <Form.Field inline>
                    <label>Admin Draft URL</label>
                    <Input
                      placeholder="Admin Draft URL"
                      onChange={(e) =>
                        this.handleChange(e, this.state.updating)
                      }
                      name="admin_draft_url"
                      value={
                        this.state.form.admin_draft_url
                          ? this.state.form.admin_draft_url
                          : ""
                      }
                    />
                  </Form.Field>
                )}
                {this.shouldShow() && (
                  <Form.Field inline>
                    <label>Primary URL</label>
                    <Input
                      placeholder="Primary URL"
                      onChange={(e) =>
                        this.handleChange(e, this.state.updating)
                      }
                      name="primary_url"
                      value={
                        this.state.form.primary_url
                          ? this.state.form.primary_url
                          : ""
                      }
                    />
                  </Form.Field>
                )}
                {this.shouldShow() && (
                  <Form.Field inline>
                    <label>Primary Anchor</label>
                    <Input
                      placeholder="Primary Anchor"
                      onChange={(e) =>
                        this.handleChange(e, this.state.updating)
                      }
                      name="primary_anchor"
                      value={
                        this.state.form.primary_anchor
                          ? this.state.form.primary_anchor
                          : ""
                      }
                    />
                  </Form.Field>
                )}
                {this.shouldShow() && (
                  <Form.Field inline>
                    <label>Secondary URL</label>
                    <Input
                      placeholder="Secondary URL"
                      onChange={(e) =>
                        this.handleChange(e, this.state.updating)
                      }
                      name="secondary_url"
                      value={
                        this.state.form.secondary_url
                          ? this.state.form.secondary_url
                          : ""
                      }
                    />
                  </Form.Field>
                )}
                {this.shouldShow() && (
                  <Form.Field inline>
                    <label>Secondary Anchor</label>
                    <Input
                      placeholder="Secondary Anchor"
                      onChange={(e) =>
                        this.handleChange(e, this.state.updating)
                      }
                      name="secondary_anchor"
                      value={
                        this.state.form.secondary_anchor
                          ? this.state.form.secondary_anchor
                          : ""
                      }
                    />
                  </Form.Field>
                )}
              </div>
            </Transition>
            {this.shouldShow() && (
              <Button
                className={`AdvancedButton ${
                  this.state.showAdvanced && "Open"
                }`}
                type="button"
                fluid
                compact
                icon
                size="tiny"
                onClick={() =>
                  this.setState({ showAdvanced: !this.state.showAdvanced })
                }
              >
                {this.state.showAdvanced ? "Hide" : "Show"} Advanced Fields{" "}
                <Icon name={this.state.showAdvanced ? "minus" : "plus"} />
              </Button>
            )}
            <div className="buttonHolder">
              {/* {this.shouldShow() ? (
                this.state.form.keywords.length === 0 ? (
                  <Popup
                    size="tiny"
                    trigger={
                      <Button
                        type="submit"
                        icon
                        labelPosition="right"
                        color="yellow"
                        size="tiny"
                      >
                        <Icon name="warning sign" />
                        {this.props.articleToEdit || this.props.templateToEdit
                          ? "Update"
                          : "Submit"}
                      </Button>
                    }
                    content="No focus keyword assigned!"
                    inverted
                  />
                ) : (
                  <Button type="submit" positive size="tiny">
                    {this.props.articleToEdit || this.props.templateToEdit
                      ? "Update"
                      : "Submit"}
                  </Button>
                )
              ) : (
                <Button type="submit" positive size="tiny">
                  {this.props.articleToEdit || this.props.templateToEdit
                    ? "Update"
                    : "Submit"}
                </Button>
              )} */}
              <Button type="submit" positive size="tiny">
                {this.props.articleToEdit || this.props.templateToEdit
                  ? "Update"
                  : "Submit"}
              </Button>
              {this.shouldShow() &&
                !this.props.addingContent &&
                (this.props.articleToEdit.contentComments &&
                this.props.articleToEdit.contentComments[0] ? (
                  <Button
                    className={`CommentsButton ${
                      this.state.showComments && "Open"
                    }`}
                    type="button"
                    fluid
                    compact
                    icon
                    primary
                    size="tiny"
                    onClick={() =>
                      this.setState({ showComments: !this.state.showComments })
                    }
                  >
                    {`${this.state.showComments ? "Hide" : "Show"} Comments`}
                    <Icon name={this.state.showComments ? "minus" : "plus"} />
                  </Button>
                ) : (
                  <Button
                    className={`CommentsButton ${
                      this.state.showComments && "Open"
                    }`}
                    type="button"
                    fluid
                    compact
                    icon
                    color="teal"
                    size="tiny"
                    onClick={() =>
                      this.setState({ showComments: !this.state.showComments })
                    }
                  >
                    {`${this.state.showComments ? "Hide" : "Add"} Comments`}
                    <Icon name={this.state.showComments ? "minus" : "plus"} />
                  </Button>
                ))}
            </div>
          </Form>
          {this.shouldShow() && !this.props.addingContent && (
            <section className="ContentArticleCommentSection">
              <Transition
                animation="slide down"
                duration={300}
                visible={this.state.showComments}
              >
                <div className="ContentArticleComments">
                  {articleComments && (
                    <div className="contentComments">
                      {articleComments.map((comment) => (
                        <CommentDetail key={comment.id} comment={comment} />
                      ))}
                    </div>
                  )}
                  <div className="contentCommentInput">
                    <CommentForm articleId={this.props.articleToEdit.id} />
                  </div>
                </div>
              </Transition>
            </section>
          )}
          {this.props.articleToEdit && (
            <ArticleChangelog article={this.props.articleToEdit} />
          )}
        </div>
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.contentGeneration.isLoading,
    isLoaded: state.contentGeneration.isLoaded,
    isAddingArticle: state.contentGeneration.isAddingArticle,
    isAddingTemplate: state.contentGeneration.isAddingTemplate,
    contentTypes: state.contentGeneration.contentTypes,
    planningMonths: state.contentGeneration.planningMonths,
    keywords: state.contentGeneration.keywords,
    // channels: state.contentGeneration.channels,
    writers: state.contentGeneration.writers,
    comments: state.contentGeneration.contentComments,
    contentStaff: state.contentGeneration.contentStaff,
    approvalStaff: state.contentGeneration.approvalStaff,
    approvalStaffLoading: state.contentGeneration.approvalStaffLoading,
    contentStatuses: state.contentGeneration.contentStatuses,
    organizations: state.organizations.organizations,
    isAddingDoc: state.contentGeneration.addingDoc,
    addedDoc: state.contentGeneration.addedDoc,
    messages: state.contentGeneration.messages,
    formMessages: state.contentGeneration.formMessages,
    isLoadingKeywords: state.contentGeneration.isLoadingKeywords,
    keywordsLoaded: state.contentGeneration.keywordsLoaded,
    newestKeyword: state.contentGeneration.newestKeyword,
    errors: state.contentGeneration.errors,
    user: state.auth.user,
    feedback: state.contentGeneration.feedback,
    isLoadingFeedback: state.contentGeneration.isLoadingFeedback,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addContentArticle: (formData, planningMonth, project) => {
      return dispatch(
        contentGeneration.addContentArticle(formData, planningMonth, project)
      );
    },
    updateContentArticle: (formData) => {
      return dispatch(contentGeneration.updateContentArticle(formData));
    },
    addArticleTemplate: (formData) => {
      return dispatch(contentGeneration.addArticleTemplate(formData));
    },
    updateArticleTemplate: (formData) => {
      return dispatch(contentGeneration.updateArticleTemplate(formData));
    },
    fetchContentTypes: () => {
      return dispatch(contentGeneration.fetchContentTypes());
    },
    fetchContentStatuses: () => {
      return dispatch(contentGeneration.fetchContentStatuses());
    },
    // fetchContentChannels: () => {
    //   return dispatch(contentGeneration.fetchContentChannels());
    // },
    fetchContentKeywords: (organizationId) => {
      return dispatch(contentGeneration.fetchContentKeywords(organizationId));
    },
    fetchPlanningMonths: () => {
      return dispatch(contentGeneration.fetchPlanningMonths());
    },
    fetchWriters: () => {
      return dispatch(contentGeneration.fetchWriters());
    },
    fetchContentStaff: () => {
      return dispatch(contentGeneration.fetchContentStaff());
    },
    fetchApprovalStaff: (orgID) => {
      return dispatch(contentGeneration.fetchApprovalStaff(orgID));
    },
    fetchOrganizations: () => {
      return dispatch(organizations.fetchOrganizations());
    },
    fetchContentComments: () => {
      return dispatch(contentGeneration.fetchContentComments());
    },
    fetchFeedback: (articleID) => {
      return dispatch(contentGeneration.fetchFeedback(articleID));
    },
    fetchArticleChangelog: (articleID) => {
      return dispatch(contentGeneration.fetchArticleChangelog(articleID));
    },
    createGoogleDoc: (
      client,
      year,
      month,
      title,
      articleId,
      projectId,
      isSheet
    ) => {
      return dispatch(
        contentGeneration.createGoogleDoc(
          client,
          year,
          month,
          title,
          articleId,
          projectId,
          isSheet
        )
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentArticleForm);
