// Auth
export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_SUCCESS_WITH_GHOST = "AUTH_SUCCESS_WITH_GHOST";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_CANCEL = "AUTH_CANCEL";
export const START_AUTH_LOGOUT = "START_AUTH_LOGOUT";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const START_CHANGE_PASSWORD_REQUEST = "START_CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_START = "CHANGE_PASSWORD_START";
export const CHANGE_PASSWORD_CANCEL = "CHANGE_PASSWORD_CANCEL";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";
export const REGISTER_START = "REGISTER_START";
export const PASSWORD_RESET_REQUEST_SUCCESS = "PASSWORD_RESET_REQUEST_SUCCESS";
export const PASSWORD_RESET_REQUEST_FAILURE = "PASSWORD_RESET_REQUEST_FAILURE";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const PASSWORD_RESET_FAILURE = "PASSWORD_RESET_FAILURE";
export const SET_AUTH_REDIRECT_PATH = "SET_AUTH_REDIRECT_PATH";
export const UPDATE_CURRENT_USER_SUCCESS = "UPDATE_CURRENT_USER_SUCCESS";

// UI
export const SET_MENU_ITEMS = "SET_MENU_ITEMS";
export const START_FETCH_UI_COMPONENTS = "START_FETCH_UI_COMPONENTS";
export const SET_UI_COMPONENTS = "SET_UI_COMPONENTS";
export const FETCH_UI_COMPONENTS_FAILURE = "FETCH_UI_COMPONENTS_FAILURE";

// General
export const START_FETCH_CONTENT_OBJECT_TYPES = "START_FETCH_CONTENT_OBJECT_TYPES";
export const FETCH_CONTENT_OBJECT_TYPES_SUCCESS = "FETCH_CONTENT_OBJECT_TYPES_SUCCESS";
export const FETCH_CONTENT_OBJECT_TYPES_FAILURE = "FETCH_CONTENT_OBJECT_TYPES_FAILURE";

// Notifications
export const UPDATE_SERVICE_WORKER = "UPDATE_SERVICE_WORKER";
export const DISMISS_NOTIFICATION = "DISMISS_NOTIFICATION";


// Users
export const START_GROUPS_FETCH = "START_GROUPS_FETCH";
export const SET_GROUPS = "SET_GROUPS";
export const GROUPS_FAILURE = "GROUPS_FAILURE";

export const SET_USERS = "SET_USERS";
export const START_USERS_FETCH = "START_USERS_FETCH";
export const FETCH_USERS_FAILED = "FETCH_USERS_FAILED";
export const START_INVITE_USER = "START_INVITE_USER";
export const INVITE_USER_SUCCESS = "ADD_USER_SUCCESS";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_PREFERENCES_SUCCESS = "UPDATE_USER_PREFERENCES_SUCCESS";
export const UPDATE_CURRENT_USER_PREFERENCES_SUCCESS = "UPDATE_CURRENT_USER_PREFERENCES_SUCCESS";
export const DELETE_USER_IN_PROGRESS = "DELETE_USER_IN_PROGRESS";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const USER_FAILURE = "USER_FAILURE";
export const SET_INVITATIONS = "SET_INVITATIONS";
export const START_INVITATIONS_FETCH = "START_INVITATIONS_FETCH";

export const SHOW_USER_SETTINGS = "SHOW_USER_SETTINGS";
export const CLOSE_USER_SETTINGS = "CLOSE_USER_SETTINGS";

export const ADD_NOTIFICATION_SUCCESS = "ADD_NOTIFICATION_SUCCESS";
export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS";
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const UPDATE_NOTIFICATION_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS";
export const FETCH_NOTIFICATIONS_FAILED = "FETCH_NOTIFICATIONS_FAILED";
export const NOTIFICATION_FAILURE = "NOTIFICATION_FAILURE";

export const START_FETCH_SYSTEM_NOTIFICATIONS = "START_FETCH_SYSTEM_NOTIFICATIONS";
export const FETCH_SYSTEM_NOTIFICATIONS_SUCCESS = "FETCH_SYSTEM_NOTIFICATIONS_SUCCESS";
export const FETCH_SYSTEM_NOTIFICATIONS_FAILURE = "FETCH_SYSTEM_NOTIFICATIONS_FAILURE";

// Content Generation
export const IS_ADDING_ARTICLE = "IS_ADDING_ARTICLE";
export const CANCEL_ADDING_ARTICLE = "CANCEL_ADDING_ARTICLE";
export const IS_ADDING_TEMPLATE = "IS_ADDING_TEMPLATE";
export const CANCEL_ADDING_TEMPLATE = "CANCEL_ADDING_TEMPLATE";
export const IS_ADDING_MONTH = "IS_ADDING_MONTH";
export const CANCEL_ADDING_MONTH = "CANCEL_ADDING_MONTH";
export const IS_ADDING_PROJECT = "IS_ADDING_PROJECT";
export const CANCEL_ADDING_PROJECT = "CANCEL_ADDING_PROJECT";

export const START_ARTICLE_FETCH = "START_ARTICLE_FETCH";
export const START_TEMPLATE_FETCH = "START_TEMPLATE_FETCH";
export const SET_CONTENT_ARTICLES = "SET_CONTENT_ARTICLES";
export const SET_PROJECT_ARTICLES = "SET_PROJECT_ARTICLES";
export const SET_ORGANIZATION_ARTICLES = "SET_ORGANIZATION_ARTICLES";
export const SET_CONTENT_DATA = "SET_CONTENT_DATA";

export const FETCH_CONTENT_ARTICLES_FAILED = "FETCH_CONTENT_ARTICLES_FAILED";
export const CONTENT_ARTICLE_FAILURE = "CONTENT_ARTICLE_FAILURE";
export const ADD_CONTENT_ARTICLE_SUCCESS = "ADD_CONTENT_ARTICLE_SUCCESS";
export const UPDATE_CONTENT_ARTICLE_SUCCESS = "UPDATE_CONTENT_ARTICLE_SUCCESS";
export const UPDATE_BULK_CONTENT_ARTICLE_SUCCESS = "UPDATE_BULK_CONTENT_ARTICLE_SUCCESS";
export const DELETE_CONTENT_ARTICLE_SUCCESS = "DELETE_CONTENT_ARTICLE_SUCCESS";

export const SET_ARTICLE_TEMPLATES = "SET_ARTICLE_TEMPLATES";
export const ADD_ARTICLE_TEMPLATE_SUCCESS = "ADD_ARTICLE_TEMPLATE_SUCCESS";
export const UPDATE_ARTICLE_TEMPLATE_SUCCESS = "UPDATE_ARTICLE_TEMPLATE_SUCCESS";
export const ARTICLE_TEMPLATE_FAILURE = "ARTICLE_TEMPLATE_FAILURE";
export const DELETE_ARTICLE_TEMPLATE_SUCCESS = "DELETE_ARTICLE_TEMPLATE_SUCCESS";

export const START_PLANNING_MONTH_FETCH = "START_PLANNING_MONTH_FETCH";
export const START_ADDITIONAL_PLANNING_MONTH_FETCH = "START_ADDITIONAL_PLANNING_MONTH_FETCH";
export const ADD_PLANNING_MONTH_SUCCESS = "ADD_PLANNING_MONTH_SUCCESS";
export const UPDATE_PLANNING_MONTH_SUCCESS = "UPDATE_PLANNING_MONTH_SUCCESS";
export const PLANNING_MONTH_FAILURE = "PLANNING_MONTH_FAILURE";

export const SET_CONTENT_PROJECTS = "SET_CONTENT_PROJECTS";
export const START_CONTENT_PROJECT_FETCH = "START_CONTENT_PROJECT_FETCH";
export const ADD_CONTENT_PROJECT_SUCCESS = "ADD_CONTENT_PROJECT_SUCCESS";
export const UPDATE_CONTENT_PROJECT_SUCCESS = "UPDATE_CONTENT_PROJECT_SUCCESS";
export const CONTENT_PROJECT_FAILURE = "CONTENT_PROJECT_FAILURE";

export const START_KEYWORD_FETCH = "START_KEYWORD_FETCH";

export const MODIFY_FILTERS = "MODIFY_FILTERS";

export const START_FEEDBACK_FETCH = "START_FEEDBACK_FETCH";
export const SET_FEEDBACK = "SET_FEEDBACK";
export const ADD_FEEDBACK_SUCCESS = "ADD_FEEDBACK_SUCCESS";
export const FEEDBACK_FAILURE = "FEEDBACK_FAILURE";
export const UPDATE_FEEDBACK_SUCCESS = "UPDATE_FEEDBACK_SUCCESS";
export const DELETE_FEEDBACK_SUCCESS = "DELETE_FEEDBACK_SUCCESS";

export const START_CONTENT_DATA_FETCH = "START_CONTENT_DATA_FETCH";
export const SET_CONTENT_TYPES = "SET_CONTENT_TYPES";
export const SET_CONTENT_STATUSES = "SET_CONTENT_STATUSES";
export const SET_CONTENT_CHANNELS = "SET_CONTENT_CHANNELS";
export const SET_CONTENT_KEYWORDS = "SET_CONTENT_KEYWORDS";
export const SET_PLANNING_MONTHS = "SET_PLANNING_MONTHS";
export const SET_ADDITIONAL_PLANNING_MONTHS = "SET_ADDITIONAL_PLANNING_MONTHS";
export const SET_WRITERS = "SET_WRITERS";
export const SET_CONTENT_STAFF = "SET_CONTENT_STAFF";
export const START_FETCH_APPROVAL_STAFF = "START_FETCH_APPROVAL_STAFF";
export const SET_APPROVAL_STAFF = "SET_APPROVAL_STAFF";
export const SET_CONTENT_TAGS = "SET_CONTENT_TAGS";
export const CONTENT_DATA_FAILURE = "CONTENT_DATA_FAILURE";

export const ADD_CONTENT_KEYWORD_SUCCESS = "ADD_CONTENT_KEYWORD_SUCCESS";
export const CONTENT_KEYWORD_FAILURE = "CONTENT_KEYWORD_FAILURE";
export const UPDATE_CONTENT_KEYWORD_SUCCESS = "UPDATE_CONTENT_KEYWORD_SUCCESS";
export const DELETE_CONTENT_KEYWORD_SUCCESS = "DELETE_CONTENT_KEYWORD_SUCCESS";

export const START_CONTENT_COMMENT_FETCH = "START_CONTENT_COMMENT_FETCH";
export const SET_CONTENT_COMMENTS = "SET_CONTENT_COMMENTS";
export const ADD_CONTENT_COMMENT_SUCCESS = "ADD_CONTENT_COMMENT_SUCCESS";
export const CONTENT_COMMENT_FAILURE = "CONTENT_COMMENT_FAILURE";
export const UPDATE_CONTENT_COMMENT_SUCCESS = "UPDATE_CONTENT_COMMENT_SUCCESS";
export const DELETE_CONTENT_COMMENT_SUCCESS = "DELETE_CONTENT_COMMENT_SUCCESS";

export const START_CREATE_GOOGLE_DOC = "START_CREATE_GOOGLE_DOC";
export const CREATE_GOOGLE_DOC_SUCCESS = "CREATE_GOOGLE_DOC_SUCCESS";
export const GOOGLE_DOC_FAILURE = "GOOGLE_DOC_FAILURE";

export const UPDATE_ARTICLE_STATUS_SUCCESS = "UPDATE_ARTICLE_STATUS_SUCCESS";
export const UPDATE_ARTICLE_STATUS_FAILURE = "UPDATE_ARTICLE_STATUS_FAILURE";

export const START_FETCH_ARTICLE_HISTORY_SETS = "START_FETCH_ARTICLE_HISTORY_SETS";
export const FETCH_ARTICLE_HISTORY_SETS_SUCCESS = "FETCH_ARTICLE_HISTORY_SETS_SUCCESS";
export const FETCH_ARTICLE_HISTORY_SETS_FAILURE = "FETCH_ARTICLE_HISTORY_SETS_FAILURE";

export const START_ARTICLE_HISTORY_SET_REFRESH = "START_ARTICLE_HISTORY_SET_REFRESH";
export const ARTICLE_HISTORY_SET_REFRESH_SUCCESS = "ARTICLE_HISTORY_SET_REFRESH_SUCCESS";
export const ARTICLE_HISTORY_SET_REFRESH_FAILURE = "ARTICLE_HISTORY_SET_REFRESH_FAILURE";

export const START_REASSIGN_ARTICLES = "START_REASSIGN_ARTICLES";
export const REASSIGN_ARTICLES_SUCCESS = "REASSIGN_ARTICLES_SUCCESS";
export const REASSIGN_ARTICLES_FAILURE = "REASSIGN_ARTICLES_FAILURE";

export const START_FETCH_ARTICLES = "START_FETCH_ARTICLES";
export const FETCH_ARTICLES_SUCCESS = "FETCH_ARTICLES_SUCCESS";
export const FETCH_ARTICLES_FAILURE = "FETCH_ARTICLES_FAILURE";

export const START_FETCH_ARTICLE_CHANGELOG = "START_FETCH_ARTICLE_CHANGELOG";
export const FETCH_ARTICLE_CHANGELOG_SUCCESS = "FETCH_ARTICLE_CHANGELOG_SUCCESS";
export const FETCH_ARTICLE_CHANGELOG_FAILURE = "FETCH_ARTICLE_CHANGELOG_FAILURE";

export const ADD_MESSAGE = "ADD_MESSAGE";
export const REMOVE_MESSAGE = "REMOVE_MESSAGE";

// Organizations
export const START_ORGANIZATIONS_FETCH = "START_ORGANIZATIONS_FETCH";
export const SET_ORGANIZATIONS = "SET_ORGANIZATIONS";
export const FETCH_ORGANIZATIONS_FAILURE = "FETCH_ORGANIZATIONS_FAILURE";
export const ADDING_ORGANIZATION = "ADDING_ORGANIZATION";
export const ADD_ORGANIZATION_SUCCESS = "ADD_ORGANIZATION_SUCCESS";
export const UPDATING_ORGANIZATION = "UPDATING_ORGANIZATION";
export const UPDATE_ORGANIZATION_SUCCESS = "UPDATE_ORGANIZATION_SUCCESS";
export const UPDATE_ORGANIZATION_FAILURE = "UPDATE_ORGANIZATION_FAILURE";
export const ARCHIVE_ORGANIZATION_SUCCESS = "ARCHIVE_ORGANIZATION_SUCCESS";
export const ORGANIZATION_FAILURE = "ORGANIZATION_FAILURE";

export const START_GET_OVERDUE_CONTENT_APPROVALS = "START_GET_OVERDUE_CONTENT_APPROVALS";
export const GET_OVERDUE_CONTENT_APPROVALS_SUCCESS = "GET_OVERDUE_CONTENT_APPROVALS_SUCCESS";
export const GET_OVERDUE_CONTENT_APPROVALS_FAILURE = "GET_OVERDUE_CONTENT_APPROVALS_FAILURE";

export const START_CREATE_WHAT_CONVERTS_ACCOUNT = "START_CREATE_WHAT_CONVERTS_ACCOUNT";
export const CREATE_WHAT_CONVERTS_ACCOUNT_SUCCESS = "CREATE_WHAT_CONVERTS_ACCOUNT_SUCCESS";
export const CREATE_WHAT_CONVERTS_ACCOUNT_FAILURE = "CREATE_WHAT_CONVERTS_ACCOUNT_FAILURE";
export const START_UPDATE_WHAT_CONVERTS_ACCOUNT = "START_UPDATE_WHAT_CONVERTS_ACCOUNT";
export const UPDATE_WHAT_CONVERTS_ACCOUNT_SUCCESS = "UPDATE_WHAT_CONVERTS_ACCOUNT_SUCCESS";
export const UPDATE_WHAT_CONVERTS_ACCOUNT_FAILURE = "UPDATE_WHAT_CONVERTS_ACCOUNT_FAILURE";

export const START_EDITORIAL_REQUIREMENTS_FETCH = "START_EDITORIAL_REQUIREMENTS_FETCH";
export const SET_EDITORIAL_REQUIREMENTS = "SET_EDITORIAL_REQUIREMENTS";
export const FETCH_EDITORIAL_REQUIREMENTS_FAILURE = "FETCH_EDITORIAL_REQUIREMENTS_FAILURE";
export const ADDING_EDITORIAL_REQUIREMENT = "ADDING_EDITORIAL_REQUIREMENT";
export const ADD_EDITORIAL_REQUIREMENT_SUCCESS = "ADD_EDITORIAL_REQUIREMENT_SUCCESS";
export const UPDATE_EDITORIAL_REQUIREMENT_SUCCESS = "UPDATE_EDITORIAL_REQUIREMENT_SUCCESS";
export const EDITORIAL_REQUIREMENT_FAILURE = "EDITORIAL_REQUIREMENT_FAILURE";
export const DELETE_EDITORIAL_REQUIREMENT_SUCCESS = "DELETE_EDITORIAL_REQUIREMENT_SUCCESS";

// User Feedback
export const START_USER_FEEDBACK_FETCH = "START_USER_FEEDBACK_FETCH";
export const SET_USER_FEEDBACK = "SET_USER_FEEDBACK";
export const FETCH_USER_FEEDBACK_FAILURE = "FETCH_USER_FEEDBACK_FAILURE";
export const ADDING_USER_FEEDBACK = "ADDING_USER_FEEDBACK";
export const ADD_USER_FEEDBACK_SUCCESS = "ADD_USER_FEEDBACK_SUCCESS";
export const UPDATE_USER_FEEDBACK_SUCCESS = "UPDATE_USER_FEEDBACK_SUCCESS";
export const USER_FEEDBACK_FAILURE = "USER_FEEDBACK_FAILURE";
export const DELETE_USER_FEEDBACK_SUCCESS = "DELETE_USER_FEEDBACK_SUCCESS";
export const START_ADDING_USER_FEEDBACK = "START_ADDING_USER_FEEDBACK";
export const CANCEL_ADDING_USER_FEEDBACK = "CANCEL_ADDING_USER_FEEDBACK";
export const TOGGLE_MINIMIZE = "TOGGLE_MINIMIZE";

// Checklists
export const START_MASTER_CHECKLIST_TEMPLATES_FETCH = "START_MASTER_CHECKLIST_TEMPLATES_FETCH";
export const SET_MASTER_CHECKLIST_TEMPLATES = "SET_MASTER_CHECKLIST_TEMPLATES";
export const UPDATE_MASTER_CHECKLIST_TEMPLATE_SUCCESS = "UPDATE_MASTER_CHECKLIST_TEMPLATE_SUCCESS";
export const MASTER_CHECKLIST_TEMPLATES_FAILURE = "MASTER_CHECKLIST_TEMPLATES_FAILURE";

    // Checklist Templates
export const START_CHECKLIST_TEMPLATES_FETCH = "START_CHECKLIST_TEMPLATES_FETCH";
export const SET_CHECKLIST_TEMPLATES = "SET_CHECKLIST_TEMPLATES";
export const START_ADDING_CHECKLIST_TEMPLATE = "START_ADDING_CHECKLIST_TEMPLATE";
export const ADD_CHECKLIST_TEMPLATE_SUCCESS = "ADD_CHECKLIST_TEMPLATE_SUCCESS";
export const UPDATE_CHECKLIST_TEMPLATE_SUCCESS = "UPDATE_CHECKLIST_TEMPLATE_SUCCESS";
export const DELETE_CHECKLIST_TEMPLATE_SUCCESS = "DELETE_CHECKLIST_TEMPLATE_SUCCESS";
export const CHECKLIST_TEMPLATES_FAILURE = "CHECKLIST_TEMPLATES_FAILURE";

    // Checklists
export const START_CHECKLISTS_FETCH = "START_CHECKLISTS_FETCH";
export const SET_CHECKLISTS = "SET_CHECKLISTS";
export const START_ADDING_CHECKLIST = "START_ADDING_CHECKLIST";
export const ADD_CHECKLIST_SUCCESS = "ADD_CHECKLIST_SUCCESS";
export const UPDATE_CHECKLIST_SUCCESS = "UPDATE_CHECKLIST_SUCCESS";
export const DELETE_CHECKLIST_SUCCESS = "DELETE_CHECKLIST_SUCCESS";
export const CHECKLISTS_FAILURE = "CHECKLISTS_FAILURE";

    // Checklist Items
export const MOVE_CHECKLIST_ITEM_SUCCESS = "MOVE_CHECKLIST_ITEM_SUCCESS";
export const INITIAL_ADD_CHECKLIST_ITEM = "INITIAL_ADD_CHECKLIST_ITEM";
export const ADD_CHECKLIST_ITEM_SUCCESS = "ADD_CHECKLIST_ITEM_SUCCESS";
export const UPDATE_CHECKLIST_ITEM_SUCCESS = "UPDATE_CHECKLIST_ITEM_SUCCESS";
export const DELETE_CHECKLIST_ITEM_SUCCESS = "DELETE_CHECKLIST_ITEM_SUCCESS";
export const CHECKLIST_ITEM_FAILURE = "CHECKLIST_ITEM_FAILURE";

    // Checklist Template Items
export const INITIAL_ADD_CHECKLIST_TEMPLATE_ITEM = "INITIAL_ADD_CHECKLIST_TEMPLATE_ITEM";
export const ADD_CHECKLIST_TEMPLATE_ITEM_SUCCESS = "ADD_CHECKLIST_TEMPLATE_ITEM_SUCCESS";
export const UPDATE_CHECKLIST_TEMPLATE_ITEM_SUCCESS = "UPDATE_CHECKLIST_TEMPLATE_ITEM_SUCCESS";
export const DELETE_CHECKLIST_TEMPLATE_ITEM_SUCCESS = "DELETE_CHECKLIST_TEMPLATE_ITEM_SUCCESS";
export const CHECKLIST_TEMPLATE_ITEM_FAILURE = "CHECKLIST_TEMPLATE_ITEM_FAILURE";
export const INITIAL_MOVE_CHECKLIST_TEMPLATE_ITEM = "INITIAL_MOVE_CHECKLIST_TEMPLATE_ITEM";
export const MOVE_CHECKLIST_TEMPLATE_ITEM_SUCCESS = "MOVE_CHECKLIST_TEMPLATE_ITEM_SUCCESS";

// Client Onboarding
export const START_WEBSITE_BUILDS_FETCH = "START_WEBSITE_BUILDS_FETCH";
export const SET_WEBSITE_BUILDS = "SET_WEBSITE_BUILDS";
export const START_ARCHIVED_WEBSITE_BUILDS_FETCH = "START_ARCHIVED_WEBSITE_BUILDS_FETCH";
export const SET_ARCHIVED_WEBSITE_BUILDS = "SET_ARCHIVED_WEBSITE_BUILDS";
export const START_ADDING_WEBSITE_BUILD = "START_ADDING_WEBSITE_BUILD";
export const ADD_WEBSITE_BUILD_SUCCESS = "ADD_WEBSITE_BUILD_SUCCESS";
export const UPDATE_WEBSITE_BUILD_SUCCESS = "UPDATE_WEBSITE_BUILD_SUCCESS";
export const DELETE_WEBSITE_BUILD_SUCCESS = "DELETE_WEBSITE_BUILD_SUCCESS";
export const WEBSITE_BUILDS_FAILURE = "WEBSITE_BUILDS_FAILURE";

// App Management
export const START_APPS_FETCH = "START_APPS_FETCH";
export const SET_APPS = "SET_APPS";
export const FETCH_APPS_FAILURE = "FETCH_APPS_FAILURE";
export const UPDATE_APP_SUCCESS = "UPDATE_APP_SUCCESS";
export const APP_FAILURE = "APP_FAILURE";
    // Initialization
export const START_APP_INITIALIZATION = "START_APP_INITIALIZATION";
export const APP_INITIALIZATION_SUCCESS = "APP_INITIALIZATION_SUCCESS";
export const APP_INITIALIZATION_FAILURE = "APP_INITIALIZATION_FAILURE";
    // Backups
export const START_APP_BACKUP = "START_APP_BACKUP";
export const APP_BACKUP_SUCCESS = "APP_BACKUP_SUCCESS";
export const APP_BACKUP_FAILURE = "APP_BACKUP_FAILURE";
    // Stop App
export const BEGIN_APP_STOP = "BEGIN_APP_STOP";
export const APP_STOP_SUCCESS = "APP_STOP_SUCCESS";
export const APP_STOP_FAILURE = "APP_STOP_FAILURE";
    // Start App
export const BEGIN_APP_START = "BEGIN_APP_START";
export const APP_START_SUCCESS = "APP_START_SUCCESS";
export const APP_START_FAILURE = "APP_START_FAILURE";
    // Status Checks
export const BEGIN_APP_STATUS_CHECK = "BEGIN_APP_STATUS_CHECK";
export const APP_STATUS_CHECK_SUCCESS = "APP_STATUS_CHECK_SUCCESS";
export const APP_STATUS_CHECK_FAILURE = "APP_STATUS_CHECK_FAILURE";
    // Backups
export const GET_S3_DOWNLOAD_SUCCESS = "GET_S3_DOWNLOAD_SUCCESS";
export const GET_S3_DOWNLOAD_FAILURE = "GET_S3_DOWNLOAD_FAILURE"
export const BEGIN_APP_DATA_BACKUP_FETCH = "BEGIN_APP_DATA_BACKUP_FETCH";
export const APP_DATA_BACKUP_FETCH_SUCCESS = "APP_DATA_BACKUP_FETCH_SUCCESS";
export const APP_DATA_BACKUP_FETCH_FAILURE = "APP_DATA_BACKUP_FETCH_FAILURE";
export const BEGIN_APP_CODE_BACKUP_FETCH = "BEGIN_APP_CODE_BACKUP_FETCH";
export const APP_CODE_BACKUP_FETCH_SUCCESS = "APP_CODE_BACKUP_FETCH_SUCCESS";
export const APP_CODE_BACKUP_FETCH_FAILURE = "APP_CODE_BACKUP_FETCH_FAILURE";
export const START_DELETE_APPLICATION = "START_DELETE_APPLICATION";
export const DELETE_APPLICATION_SUCCESS = "DELETE_APPLICATION_SUCCESS";
    // Child Themes
export const START_THEME_FETCH = "START_THEME_FETCH";
export const SET_THEMES = "SET_THEMES";
export const THEME_FAILURE = "THEME_FAILURE";

// Utilities
export const START_LINK_CHECK = "START_LINK_CHECK";
export const LINK_CHECK_SUCCESS = "LINK_CHECK_SUCCESS";
export const LINK_CHECK_FAILURE = "LINK_CHECK_FAILURE";

// Reporting
export const START_FETCH_REPORTS = "START_FETCH_REPORTS";
export const FETCH_REPORTS_SUCCESS = "FETCH_REPORTS_SUCCESS";
export const FETCH_REPORTS_FAILURE = "FETCH_REPORTS_FAILURE";
export const START_UPDATE_REPORT = "START_UPDATE_REPORT";
export const UPDATE_REPORT_SUCCESS = "UPDATE_REPORT_SUCCESS";
export const UPDATE_REPORT_FAILURE = "UPDATE_REPORT_FAILURE";
export const START_SEND_REPORT = "START_SEND_REPORT";
export const SEND_REPORT_SUCCESS = "SEND_REPORT_SUCCESS";
export const SEND_REPORT_FAILURE = "SEND_REPORT_FAILURE";
export const START_CREATE_REPORT_PRESENTATION = "START_CREATE_REPORT_PRESENTATION";
export const CREATE_REPORT_PRESENTATION_SUCCESS = "CREATE_REPORT_PRESENTATION_SUCCESS";
export const CREATE_REPORT_PRESENTATION_FAILURE = "CREATE_REPORT_PRESENTATION_FAILURE";
export const START_RE_CREATE_REPORT_PRESENTATION = "START_RE_CREATE_REPORT_PRESENTATION";
export const RE_CREATE_REPORT_PRESENTATION_SUCCESS = "RE_CREATE_REPORT_PRESENTATION_SUCCESS";
export const RE_CREATE_REPORT_PRESENTATION_FAILURE = "RE_CREATE_REPORT_PRESENTATION_FAILURE";
export const START_CREATE_REPORT_PRESENTATION_FROM_MASTER = "START_CREATE_REPORT_PRESENTATION_FROM_MASTER";
export const CREATE_REPORT_PRESENTATION_FROM_MASTER_SUCCESS = "CREATE_REPORT_PRESENTATION_FROM_MASTER_SUCCESS";
export const CREATE_REPORT_PRESENTATION_FROM_MASTER_FAILURE = "CREATE_REPORT_PRESENTATION_FROM_MASTER_FAILURE";
export const START_CREATE_MONTHLY_REPORTS = "START_CREATE_MONTHLY_REPORTS";
export const CREATE_MONTHLY_REPORTS_SUCCESS = "CREATE_MONTHLY_REPORTS_SUCCESS";
export const CREATE_MONTHLY_REPORTS_FAILURE = "CREATE_MONTHLY_REPORTS_FAILURE";
export const START_VALIDATE_MONTHLY_REPORT_DATA = "START_VALIDATE_MONTHLY_REPORT_DATA";
export const VALIDATE_MONTHLY_REPORT_DATA_SUCCESS = "VALIDATE_MONTHLY_REPORT_DATA_SUCCESS";
export const VALIDATE_MONTHLY_REPORT_DATA_FAILURE = "VALIDATE_MONTHLY_REPORT_DATA_FAILURE";
export const START_CREATE_MONTHLY_REPORT_PRESENTATIONS = "START_CREATE_MONTHLY_REPORT_PRESENTATIONS";
export const CREATE_MONTHLY_REPORT_PRESENTATIONS_SUCCESS = "CREATE_MONTHLY_REPORT_PRESENTATIONS_SUCCESS";
export const CREATE_MONTHLY_REPORT_PRESENTATIONS_FAILURE = "CREATE_MONTHLY_REPORT_PRESENTATIONS_FAILURE";
export const START_QUERY_UNSENT_REPORTS = "START_QUERY_UNSENT_REPORTS";
export const QUERY_UNSENT_REPORTS_SUCCESS = "QUERY_UNSENT_REPORTS_SUCCESS";
export const QUERY_UNSENT_REPORTS_FAILURE = "QUERY_UNSENT_REPORTS_FAILURE";
export const START_BULK_REPORT_PRESENTATION_TEMPLATE_UPDATE = "START_BULK_REPORT_PRESENTATION_TEMPLATE_UPDATE";
export const BULK_REPORT_PRESENTATION_TEMPLATE_UPDATE_SUCCESS = "BULK_REPORT_PRESENTATION_TEMPLATE_UPDATE_SUCCESS";
export const BULK_REPORT_PRESENTATION_TEMPLATE_UPDATE_FAILURE = "BULK_REPORT_PRESENTATION_TEMPLATE_UPDATE_FAILURE";
export const START_FETCH_REPORT_ENTRIES = "START_FETCH_REPORT_ENTRIES";
export const FETCH_REPORT_ENTRIES_SUCCESS = "FETCH_REPORT_ENTRIES_SUCCESS";
export const FETCH_REPORT_ENTRIES_FAILURE = "FETCH_REPORT_ENTRIES_FAILURE";

// Accounts
export const START_FETCH_ACCOUNTS = "START_FETCH_ACCOUNTS";
export const SET_ACCOUNTS = "SET_ACCOUNTS";
export const FETCH_ACCOUNTS_FAILURE = "FETCH_ACCOUNTS_FAILURE";
export const ADD_ACCOUNT_SUCCESS = "ADD_ACCOUNT_SUCCESS";
export const ADD_ACCOUNT_FAILURE = "ADD_ACCOUNT_FAILURE";
export const START_UPDATE_ACCOUNT = "START_UPDATE_ACCOUNT";
export const UPDATE_ACCOUNT_SUCCESS = "UPDATE_ACCOUNT_SUCCESS";
export const UPDATE_ACCOUNT_FAILRE = "UPDATE_ACCOUNT_FAILRE";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_FAILURE = "DELETE_ACCOUNT_FAILURE";

// User password
export const START_USER_PASSWORD_CHANGE = "START_USER_PASSWORD_CHANGE";
export const USER_PASSWORD_CHANGE_SUCCESS = "USER_PASSWORD_CHANGE_SUCCESS";
export const USER_PASSWORD_CHANGE_FAILURE = "USER_PASSWORD_CHANGE_FAILURE";

export const START_FETCH_CURRENT_ASSIGNMENTS = "START_FETCH_CURRENT_ASSIGNMENTS";
export const FETCH_CURRENT_ASSIGNMENTS_SUCCESS = "FETCH_CURRENT_ASSIGNMENTS_SUCCESS";
export const FETCH_CURRENT_ASSIGNMENTS_FAILURE = "FETCH_CURRENT_ASSIGNMENTS_FAILURE";

export const START_FETCH_LATE_ASSIGNMENTS = "START_FETCH_LATE_ASSIGNMENTS";
export const FETCH_LATE_ASSIGNMENTS_SUCCESS = "FETCH_LATE_ASSIGNMENTS_SUCCESS";
export const FETCH_LATE_ASSIGNMENTS_FAILURE = "FETCH_LATE_ASSIGNMENTS_FAILURE";

export const START_FETCH_DUE_SOON_ASSIGNMENTS = "START_FETCH_DUE_SOON_ASSIGNMENTS";
export const FETCH_DUE_SOON_ASSIGNMENTS_SUCCESS = "FETCH_DUE_SOON_ASSIGNMENTS_SUCCESS";
export const FETCH_DUE_SOON_ASSIGNMENTS_FAILURE = "FETCH_DUE_SOON_ASSIGNMENTS_FAILURE";

//** Presentations

// Slide Deck Templates
export const START_SLIDE_DECK_TEMPLATE_FETCH = "START_SLIDE_DECK_TEMPLATE_FETCH";
export const SET_SLIDE_DECK_TEMPLATES = "SET_SLIDE_DECK_TEMPLATES";
export const START_ADD_SLIDE_DECK_TEMPLATE = "START_ADD_SLIDE_DECK_TEMPLATE";
export const ADD_SLIDE_DECK_TEMPLATE_SUCCESS = "ADD_SLIDE_DECK_TEMPLATE_SUCCESS";
export const START_UPDATE_SLIDE_DECK_TEMPLATE = "START_UPDATE_SLIDE_DECK_TEMPLATE";
export const UPDATE_SLIDE_DECK_TEMPLATE_SUCCESS = "UPDATE_SLIDE_DECK_TEMPLATE_SUCCESS";
export const DELETE_SLIDE_DECK_TEMPLATE_SUCCESS = "DELETE_SLIDE_DECK_TEMPLATE_SUCCESS";
export const SLIDE_DECK_TEMPLATE_ERROR = "SLIDE_DECK_TEMPLATE_ERROR";
export const UPDATE_ORGANIZATION_SLIDE_DECK_TEMPLATE = "UPDATE_ORGANIZATION_SLIDE_DECK_TEMPLATE";

// Slide Templates
export const START_SLIDE_TEMPLATE_FETCH = "START_SLIDE_TEMPLATE_FETCH";
export const SET_SLIDE_TEMPLATES = "SET_SLIDE_TEMPLATES";
export const START_ADD_SLIDE_TEMPLATE = "START_ADD_SLIDE_TEMPLATE";
export const ADD_SLIDE_TEMPLATE_SUCCESS = "ADD_SLIDE_TEMPLATE_SUCCESS";
export const UPDATE_SLIDE_TEMPLATE_SUCCESS = "UPDATE_SLIDE_TEMPLATE_SUCCESS";
export const DELETE_SLIDE_TEMPLATE_SUCCESS = "DELETE_SLIDE_TEMPLATE_SUCCESS";
export const SLIDE_TEMPLATE_ERROR = "SLIDE_TEMPLATE_ERROR";

// Slide Decks
export const START_SLIDE_DECK_FETCH = "START_SLIDE_DECK_FETCH";
export const SET_SLIDE_DECKS = "SET_SLIDE_DECKS";
export const SLIDE_DECK_ERROR = "SLIDE_DECK_ERROR";


// Slides
export const START_SLIDE_FETCH = "START_SLIDE_FETCH";
export const SET_SLIDES = "SET_SLIDES";
export const SLIDE_ERROR = "SLIDE_ERROR";

// Account Health
export const START_FETCH_ACCOUNT_HEALTH_OVERVIEWS = "START_FETCH_ACCOUNT_HEALTH_OVERVIEWS";
export const FETCH_ACCOUNT_HEALTH_OVERVIEWS_SUCCESS = "FETCH_ACCOUNT_HEALTH_OVERVIEWS_SUCCESS";
export const FETCH_ACCOUNT_HEALTH_OVERVIEWS_FALURE = "FETCH_ACCOUNT_HEALTH_OVERVIEWS_FALURE";

// Wordpress
export const START_FETCH_POSTS = "START_FETCH_POSTS";
export const FETCH_POSTS_SUCCESS = "FETCH_POSTS_SUCCESS";
export const FETCH_POSTS_FALURE = "FETCH_POSTS_FALURE";

export const START_FETCH_POST = "START_FETCH_POST";
export const FETCH_POST_SUCCESS = "FETCH_POST_SUCCESS";
export const FETCH_POST_FALURE = "FETCH_POST_FALURE";

export const START_BULK_ACCOUNT_POSTS = "START_BULK_ACCOUNT_POSTS";
export const FETCH_SINGLE_BULK_ACCOUNT_POSTS_SUCCESS = "FETCH_SINGLE_BULK_ACCOUNT_POSTS_SUCCESS";
export const FETCH_BULK_ACCOUNT_POSTS_SUCCESS = "FETCH_BULK_ACCOUNT_POSTS_SUCCESS";
export const FETCH_BULK_ACCOUNT_POSTS_FALURE = "FETCH_BULK_ACCOUNT_POSTS_FALURE";

export const START_FETCH_WP_USER = "START_FETCH_WP_USER";
export const FETCH_WP_USER_SUCCESS = "FETCH_WP_USER_SUCCESS";
export const FETCH_WP_USER_FALURE = "FETCH_WP_USER_FALURE";