import React, { Component } from 'react';
import { contentGeneration, organizations } from '../../../store/actions';
import { connect } from 'react-redux';
import FixedLoader from '../../../components/FixedLoader/FixedLoader';
import SortableTable from '../../../components/SortableTable/SortableTable';
import ActionHeader from '../../../components/ActionHeader/ActionHeader';
import moment from 'moment';

/*eslint eqeqeq: "off"*/

class SingleOrganizationView extends Component {
	state = {
		isFetching: false
	};
	componentDidMount() {
		!this.props.writers[0] && this.props.fetchWriters();
		// !this.props.organizations[0] &&
		//   (!this.props.isLoadingOrganizations && !this.props.loadedOrganizations) &&
		//   this.props.fetchOrganizations();
		!this.props.comments[0] && this.props.fetchContentComments();
		!this.props.contentStatuses[0] && this.props.fetchContentStatuses();
		!this.props.isLoadingOrganizations &&
			this.props.organization &&
			this.props.organization.id &&
			this.handleFetch();
		this.props.contentStaff && !this.props.contentStaff[0] && this.props.fetchContentStaff();
	}

	componentDidUpdate(prevProps) {
		if (!this.props.isLoadingOrganizations && this.props.organization !== prevProps.organization) {
			this.handleFetch();
		}
	}

	handleFetch = async () => {
		await this.setState({ isFetching: true });
		await this.props.fetchOrganizationArticles(this.props.organization.id);
		return this.setState({ isFetching: false });
	};

	render() {
		return (
			<div>
				<ActionHeader
					headerText={`Single Organization View - ${this.props.organization
						? this.props.organization.dba_name
						: 'Loading...'}`}
				/>
				{!this.props.isLoading && this.props.isLoaded ? (
					<SortableTable
						isSingleOrganization={true}
						planningMonths={this.props.planningMonths}
						selectedArticles={this.props.selectedArticles}
						// handleMultiSelect={this.props.handleMultiSelect}
						selectedItem={this.props.articleToEdit}
						writers={this.props.writers}
						contentStaff={this.props.contentStaff}
						organizations={this.props.organizations}
						contentStatuses={this.props.contentStatuses}
						handleSelect={this.props.selectArticle}
						originalArticles={this.props.contentArticles}
						tableData={this.props.contentArticles
							.filter((contentArticle) => contentArticle.client.id == this.props.organization.id)
							.sort(
								(a, b) =>
									moment([ a.planning_year_month.year, a.planning_year_month.month, 1 ]) -
										moment([ b.planning_year_month.year, b.planning_year_month.month, 1 ]) ||
									a.project.name - b.project.name
							)}
					/>
				) : (
					<FixedLoader />
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		contentArticles: state.contentGeneration.contentArticles,
		writers: state.contentGeneration.writers,
		contentStatuses: state.contentGeneration.contentStatuses,
		isLoading: state.contentGeneration.isLoadingArticles,
		isLoaded: state.contentGeneration.articlesLoaded,
		isLoadingOrganizations: state.organizations.isLoading,
		loadedOrganizations: state.organizations.isLoaded,
		isAddingArticle: state.contentGeneration.isAddingArticle,
		comments: state.contentGeneration.contentComments,
		organizations: state.organizations.organizations,
		planningMonths: state.contentGeneration.planningMonths,
		contentStaff: state.contentGeneration.contentStaff,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchContentArticles: () => {
			dispatch(contentGeneration.fetchContentArticles());
		},
		fetchOrganizationArticles: (orgID) => {
			return dispatch(contentGeneration.fetchOrganizationArticles(orgID));
		},
		fetchContentStatuses: () => {
			return dispatch(contentGeneration.fetchContentStatuses());
		},
		fetchContentComments: () => {
			return dispatch(contentGeneration.fetchContentComments());
		},
		fetchWriters: () => {
			return dispatch(contentGeneration.fetchWriters());
		},
		fetchOrganizations: () => {
			return dispatch(organizations.fetchOrganizations());
		},
		fetchContentStaff: () => dispatch(contentGeneration.fetchContentStaff()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleOrganizationView);
