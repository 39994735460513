import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';
/*eslint eqeqeq: "off"*/

const initialState = {
	contentArticles: [],
	articleTemplates: [],
	articles: {
		data: [],
		isLoading: false,
		isLoaded: false,
		errors: null
	},
	contentComments: [],
	feedback: [],
	contentTypes: [],
	planningMonths: [],
	contentProjects: [],
	keywords: [],
	channels: [],
	writers: [],
	contentStaff: [],
	approvalStaff: [],
	approvalStaffLoading: false,
	contentStatuses: [],
	contentTags: [],
	articleHistorySets: {
		data: [],
		isLoading: false,
		isLoaded: false,
		isRefreshing: null,
		errors: null
	},
	selectedMonth: null,
	selectedProject: null,
	isLoadingArticles: false,
	articlesLoaded: false,
	isLoadingTemplates: false,
	templatesLoaded: false,
	isContentDataLoading: false,
	isContentDataLoaded: false,
	isLoadingMonths: false,
	isLoadingAdditionalMonths: false,
	isLoadingProjects: false,
	isLoadingKeywords: false,
	isLoadingContentComments: false,
	contentCommentsLoaded: false,
	isLoadingFeedback: false,
	feedbackLoaded: false,
	keywordsLoaded: false,
	showPlanningMonth: false,
	showContentProject: false,
	isAddingArticle: false,
	isAddingTemplate: false,
	isAddingMonth: false,
	isAddingProject: false,
	addedMonth: false,
	addedProject: false,
	newProject: null,
	addingDoc: false,
	addedDoc: true,
	newestKeyword: null,
	didReAssign: false,
	reAssigningInProgress: false,
	reAssignMessage: null,
	errors: [],
	messages: [],
	formMessages: [],
	changelog: [],
	isFetchingChangelog: false,
	fetchedChangelog: false,
	componentFilters: {
		component: null,
		filters: null
	}
};

const modifyFilters = (state, action) => {
	return updateObject(state, {
		componentFilters: {
			component: action.component,
			filters: action.filters
		}
	});
};

const isAddingArticle = (state, action) => {
	return updateObject(state, {
		isAddingArticle: true,
		showDetails: true,
		selectedArticle: null
	});
};

const cancelAddingArticle = (state, action) => {
	return updateObject(state, {
		isAddingArticle: false
	});
};

const isAddingTemplate = (state, action) => {
	return updateObject(state, {
		isAddingTemplate: true,
		showDetails: true,
		selectedTemplate: null
	});
};

const cancelAddingTemplate = (state, action) => {
	return updateObject(state, {
		isAddingTemplate: false
	});
};

const isAddingMonth = (state, action) => {
	return updateObject(state, {
		isAddingMonth: true,
		addedMonth: false
	});
};

const cancelAddingMonth = (state, action) => {
	return updateObject(state, {
		isAddingMonth: false,
		showPlanningMonth: false
	});
};

const isAddingProject = (state, action) => {
	return updateObject(state, {
		isAddingProject: true,
		addedProject: false,
		newProject: null
	});
};

const cancelAddingProject = (state, action) => {
	return updateObject(state, {
		isAddingProject: false,
		showContentProject: false
	});
};

const startArticleFetch = (state, action) => {
	return updateObject(state, { isLoadingArticles: true, addedMonth: false });
};

const startTemplateFetch = (state, action) => {
	return updateObject(state, { isLoadingTemplates: true, addedMonth: false });
};

const startContentDataFetch = (state, action) => {
	return updateObject(state, { isContentDataLoading: true, addedMonth: false });
};

const startPlanningMonthFetch = (state, action) => {
	return updateObject(state, { isLoadingMonths: true, errors: false });
};

const startAdditionalPlanningMonthFetch = (state, action) => {
	return updateObject(state, { isLoadingAdditionalMonths: true, errors: false });
};

const startContentProjectFetch = (state, action) => {
	return updateObject(state, { isLoadingProjects: true, errors: false });
};

const startKeywordFetch = (state, action) => {
	return updateObject(state, { isLoadingKeywords: true, addedMonth: false });
};

const setContentArticles = (state, action) => {
	// console.log('incoming articles are: ', action.contentArticles, 'count is: ', action.contentArticles.length);
	if (action.year && action.month) {
		if (action.planningMonth) {
			const articlesToUpdate = state.contentArticles.filter(
				(article) => article.planning_year_month.id !== action.planningMonth.planning_year_month.id
			);
			return updateObject(state, {
				contentArticles: articlesToUpdate.concat(action.contentArticles),
				isLoadingArticles: false,
				articlesLoaded: true,
				errors: null
			});
		} else {
			if (state.contentArticles[0]) {
				return updateObject(state, {
					contentArticles: state.contentArticles.concat(action.contentArticles),
					isLoadingArticles: false,
					articlesLoaded: true,
					errors: null
				});
			} else {
				return updateObject(state, {
					contentArticles: action.contentArticles,
					isLoadingArticles: false,
					articlesLoaded: true,
					errors: null
				});
			}
		}
	} else {
		return updateObject(state, {
			contentArticles: action.contentArticles,
			isLoadingArticles: false,
			articlesLoaded: true,
			errors: null
		});
	}
};

const setProjectArticles = (state, action) => {
	return updateObject(state, {
		contentArticles: action.contentArticles,
		isLoadingArticles: false,
		articlesLoaded: true,
		errors: null
	});
};

const setOrganizationArticles = (state, action) => {
	return updateObject(state, {
		contentArticles: action.contentArticles,
		isLoadingArticles: false,
		articlesLoaded: true,
		errors: null
	});
};

const setArticleTemplates = (state, action) => {
	return updateObject(state, {
		articleTemplates: action.articleTemplates,
		isLoadingTemplates: false,
		templatesLoaded: true,
		errors: null
	});
};

const setContentComments = (state, action) => {
	return updateObject(state, {
		contentComments: action.contentComments,
		isLoadingContentComments: false,
		contentCommentsLoaded: true,
		errors: null
	});
};

const setFeedback = (state, action) => {
	return updateObject(state, {
		feedback: action.feedback,
		isLoadingFeedback: false,
		feedbackLoaded: true,
		errors: null
	});
};

const setContentTypes = (state, action) => {
	return updateObject(state, {
		contentTypes: action.contentTypes,
		isContentDataLoading: false,
		errors: null
	});
};
const setContentStatuses = (state, action) => {
	return updateObject(state, {
		contentStatuses: action.contentStatuses,
		isContentDataLoading: false,
		errors: null
	});
};
const setContentChannels = (state, action) => {
	return updateObject(state, {
		channels: action.contentChannels,
		isContentDataLoading: false,
		errors: null
	});
};
const setContentKeywords = (state, action) => {
	return updateObject(state, {
		keywords: action.contentKeywords,
		isLoadingKeywords: false,
		keywordsLoaded: true,
		errors: null
	});
};
const setPlanningMonths = (state, action) => {
	return updateObject(state, {
		planningMonths: action.planningMonths,
		isLoadingMonths: false,
		errors: null
	});
};

const setAdditionalPlanningMonths = (state, action) => {
	return updateObject(state, {
		planningMonths: state.planningMonths.concat(action.planningMonths),
		isLoadingAdditionalMonths: false,
		errors: null
	});
};

const setContentProjects = (state, action) => {
	return updateObject(state, {
		contentProjects: action.projects,
		isLoadingProjects: false,
		errors: null
	});
};
const setContentTags = (state, action) => {
	return updateObject(state, {
		contentTags: action.contentTags,
		isContentDataLoading: false,
		errors: null
	});
};
const setWriters = (state, action) => {
	return updateObject(state, {
		writers: action.writers,
		isContentDataLoading: false,
		errors: null
	});
};

const setContentStaff = (state, action) => {
	return updateObject(state, {
		contentStaff: action.contentStaff,
		isContentDataLoading: false,
		errors: null
	});
};

const startFetchApprovalStaff = (state, action) => {
	return updateObject(state, {
		approvalStaffLoading: true,
		approvalStaff: []
	});
};

const setApprovalStaff = (state, action) => {
	return updateObject(state, {
		approvalStaff: action.approvalStaff,
		approvalStaffLoading: false,
		isContentDataLoading: false,
		errors: null
	});
};

const fetchContentArticlesFailed = (state, action) => {
	return updateObject(state, {
		isLoadingArticles: false,
		isLoadingTemplates: false,
		approvalStaffLoading: false,
		errors: action.errors
	});
};

const addContentArticleSuccess = (state, action) => {
	return updateObject(state, {
		contentArticles: state.contentArticles.concat(action.contentArticle),
		isAddingArticle: false,
		showDetails: false,
		errors: null
	});
};

const updateContentArticleSuccess = (state, action) => {
	let contentArticleIndex = state.contentArticles.findIndex((x) => x.id === action.id);
	return updateObject(state, {
		contentArticles: state.contentArticles.map(
			(contentArticle, i) => (i === contentArticleIndex ? action.contentArticle : contentArticle)
		),
		errors: null
	});
};

const updateBulkContentArticleSuccess = (state, action) => {
	return updateObject(state, {
		contentArticles: state.contentArticles.map(
			(contentArticle, i) =>
				action.articleIndexes.indexOf(i) > -1
					? action.contentArticles.find((article) => article.id === contentArticle.id)
					: contentArticle
		),
		errors: null
	});
};

const deleteContentArticleSuccess = (state, action) => {
	let updatedArticles = state.contentArticles.filter((contentArticle) => contentArticle.id !== action.id);
	return updateObject(state, {
		contentArticles: updatedArticles
	});
};

const addArticleTemplateSuccess = (state, action) => {
	return updateObject(state, {
		articleTemplates: state.articleTemplates.concat(action.contentTemplate),
		isAddingArticle: false,
		showDetails: false,
		errors: null
	});
};

const updateArticleTemplateSuccess = (state, action) => {
	let articleTemplateIndex = state.articleTemplates.findIndex((x) => x.id === action.id);
	return updateObject(state, {
		articleTemplates: state.articleTemplates.map(
			(articleTemplate, i) => (i === articleTemplateIndex ? action.contentTemplate : articleTemplate)
		),
		errors: null
	});
};

const addPlanningMonthSuccess = (state, action) => {
	return updateObject(state, {
		planningMonths: state.planningMonths.concat(action.planningMonth),
		isAddingMonth: false,
		addedMonth: true
	});
};

const updatePlanningMonthSuccess = (state, action) => {
	let monthToUpdate = state.contentData.planning_months.find((month) => month.id === action.planningMonth.id);
	let filteredMonths = state.contentData.planning_months.filter((filters) => filters !== monthToUpdate);
	let updatedMonths = filteredMonths.concat(action.planningMonth);

	return updateObject(state, {
		contentData: {
			...state.contentData,
			monthToUpdate: updatedMonths
		}
	});
};

const planningMonthFailure = (state, action) => {
	return updateObject(state, {
		errors: action.errors,
		isAddingMonth: false,
		addedMonth: false
	});
};

// Content Project Reducers

const addContentProjectSuccess = (state, action) => {
	return updateObject(state, {
		contentProjects: state.contentProjects.concat(action.project),
		isAddingProject: false,
		addedProject: true,
		newProject: action.project
	});
};

const updateContentProjectSuccess = (state, action) => {
	let contentProjectIndex = state.contentProjects.findIndex((x) => x.id === action.project.id);
	return updateObject(state, {
		contentProjects: state.contentProjects.map(
			(project, i) => (i === contentProjectIndex ? action.project : project)
		),
		errors: null
	});
};

const contentProjectFailure = (state, action) => {
	return updateObject(state, {
		errors: action.errors,
		isAddingProject: false,
		addedProject: false
	});
};

const addContentKeywordSuccess = (state, action) => {
	return updateObject(state, {
		keywords: state.keywords.concat(action.contentKeyword),
		newestKeyword: action.contentKeyword,
		errors: null
	});
};

const updateContentKeywordSuccess = (state, action) => {
	let contentKeywordIndex = state.keywords.findIndex((x) => x.id === action.id);
	return updateObject(state, {
		keywords: state.keywords.map((keyword, i) => (i === contentKeywordIndex ? action.contentKeyword : keyword)),
		errors: null
	});
};

const deleteContentKeywordSuccess = (state, action) => {
	let updatedKeywords = state.keywords.filter((contentKeyword) => contentKeyword.id !== action.id);
	return updateObject(state, {
		keywords: updatedKeywords
	});
};

const contentKeywordFailure = (state, action) => {
	return updateObject(state, {
		errors: action.errors
	});
};

const deleteArticleTemplateSuccess = (state, action) => {
	let updatedArticleTemplates = state.articleTemplates.filter((articleTemplate) => articleTemplate.id !== action.id);
	return updateObject(state, {
		articleTemplates: updatedArticleTemplates
	});
};

const contentArticleFailure = (state, action) => {
	return updateObject(state, {
		errors: action.errors,
		isLoadingArticles: false
	});
};

const articleTemplateFailure = (state, action) => {
	return updateObject(state, {
		errors: action.errors,
		isLoadingTemplates: false
	});
};

const contentDataFailure = (state, action) => {
	return updateObject(state, { errors: action.errors, isLoadingMonths: false, isLoadingAdditionalMonths: false });
};

const addMessage = (state, action) => {
	if (action.formMessage) {
		return updateObject(state, {
			formMessages: state.formMessages.concat(action.message)
		});
	} else {
		return updateObject(state, {
			messages: state.messages.concat(action.message)
		});
	}
};

const removeMessage = (state, action) => {
	if (action.formMessage) {
		let messagesList = state.formMessages.slice();
		messagesList.splice(0, 1);
		return updateObject(state, { formMessages: messagesList });
	} else {
		let messagesList = state.messages.slice();
		messagesList.splice(0, 1);
		return updateObject(state, { messages: messagesList });
	}
};

const addContentCommentSuccess = (state, action) => {
	return updateObject(state, {
		contentComments: state.contentComments.concat(action.contentComment),
		errors: null
	});
};

const updateContentCommentSuccess = (state, action) => {
	let contentCommentIndex = state.contentComments.findIndex((x) => x.id === action.id);
	return updateObject(state, {
		contentComments: state.contentComments.map(
			(contentComment, i) => (i === contentCommentIndex ? action.contentComment : contentComment)
		),
		errors: null
	});
};

const deleteContentCommentSuccess = (state, action) => {
	let updatedContentComments = state.contentComments.filter((contentComment) => contentComment.id !== action.id);
	return updateObject(state, {
		contentComments: updatedContentComments
	});
};

const addFeedbackSuccess = (state, action) => {
	return updateObject(state, {
		feedback: state.feedback.concat(action.feedback),
		errors: null
	});
};

const updateFeedbackSuccess = (state, action) => {
	let feedbackIndex = state.feedback.findIndex((x) => x.id === action.id);
	return updateObject(state, {
		feedback: state.feedback.map((feedback, i) => (i === feedbackIndex ? action.feedback : feedback)),
		errors: null
	});
};

const deleteFeedbackSuccess = (state, action) => {
	let updatedFeedback = state.feedback.filter((feedback) => feedback.id !== action.id);
	return updateObject(state, {
		feedback: updatedFeedback
	});
};

export const startCreateGoogleDoc = (state, action) => {
	return updateObject(state, {
		addingDoc: true,
		addedDoc: false
	});
};

export const createGoogleDocSuccess = (state, action) => {
	let articleIndex = state.contentArticles.findIndex((x) => x.id === action.articleId);
	console.log('isSheet is: ', action.isSheet)
	if (action.isSheet === true) {
		return updateObject(state, {
			contentArticles: state.contentArticles.map(
				(article, i) => (i === articleIndex ? { ...article, google_sheet: action.doc.url } : article)
			),
			errors: null,
			addingDoc: false,
			addedDoc: true
		});
	} else {
		return updateObject(state, {
			contentArticles: state.contentArticles.map(
				(article, i) => (i === articleIndex ? { ...article, google_doc: action.doc.url } : article)
			),
			errors: null,
			addingDoc: false,
			addedDoc: true
		});
	}
};

export const googleDocFailure = (state, action) => {
	return updateObject(state, {
		addingDoc: false,
		addedDoc: false,
		errors: action.errors
	});
};

export const updateArticleStatusSuccess = (state, action) => {
	let contentArticleIndex = state.contentArticles.findIndex((x) => x.id === action.articleId);
	// let newStatus = state.contentStatuses.find((status) => status.order === action.newStatus);
	let newStatus = state.contentStatuses.find((status) => status.uid === action.newStatus);
	return updateObject(state, {
		contentArticles: state.contentArticles.map(
			(contentArticle, i) =>
				i === contentArticleIndex
					? {
							...contentArticle,
							status: newStatus,
							...action.args,
							// scheduled_date: action.scheduledDate,
							// writer_planned_duedate: action.writerDueDate,
							// live_url: action.postedUrl
						}
					: contentArticle
		),
		errors: null
	});
};

export const updateArticleStatusFailure = (state, action) => {
	return updateObject(state, {
		errors: action.errors
	});
};

const startCreateMonthlyReports = (state, action) => {
	return updateObject(state, {
		planningMonths: state.planningMonths.map(
			(pm, i) =>
				i === action.monthIndex
					? {
							...pm,
							isCreatingReports: true
						}
					: pm
		)
	});
};
const createMonthlyReportsSuccess = (state, action) => {
	return updateObject(state, {
		planningMonths: state.planningMonths.map(
			(pm, i) =>
				i === action.monthIndex
					? {
							...pm,
							isCreatingReports: false,
							didCreateReports: true
						}
					: pm
		)
	});
};
const createMonthlyReportsFailure = (state, action) => {
	return updateObject(state, {
		planningMonths: state.planningMonths.map(
			(pm, i) =>
				i === action.monthIndex
					? {
							...pm,
							isCreatingReports: false,
							reportErrors: action.errors,
							didCreateReports: false
						}
					: pm
		)
	});
};

const startValidateMonthlyReportData = (state, action) => {
	return updateObject(state, {
		planningMonths: state.planningMonths.map(
			(pm, i) =>
				i === action.monthIndex
					? {
							...pm,
							isValidatingReports: true
						}
					: pm
		)
	});
};
const validateMonthlyReportDataSuccess = (state, action) => {
	return updateObject(state, {
		planningMonths: state.planningMonths.map(
			(pm, i) =>
				i === action.monthIndex
					? {
							...pm,
							isValidatingReports: false,
							didValidateReports: true
						}
					: pm
		)
	});
};
const validateMonthlyReportDataFailure = (state, action) => {
	return updateObject(state, {
		planningMonths: state.planningMonths.map(
			(pm, i) =>
				i === action.monthIndex
					? {
							...pm,
							isValidatingReports: false,
							didValidateReports: false,
							errors: action.errors
						}
					: pm
		)
	});
};

const startCreateMonthlyReportPresentations = (state, action) => {
	return updateObject(state, {
		planningMonths: state.planningMonths.map(
			(pm, i) =>
				i === action.monthIndex
					? {
							...pm,
							isCreatingReportPresentations: true
						}
					: pm
		)
	});
};
const createMonthlyReportPresentationsSuccess = (state, action) => {
	return updateObject(state, {
		planningMonths: state.planningMonths.map(
			(pm, i) =>
				i === action.monthIndex
					? {
							...pm,
							isCreatingReportPresentations: false,
							createdReportPresentations: true
						}
					: pm
		)
	});
};
const createMonthlyReportPresentationsFailure = (state, action) => {
	return updateObject(state, {
		planningMonths: state.planningMonths.map(
			(pm, i) =>
				i === action.monthIndex
					? {
							...pm,
							isCreatingReportPresentations: false,
							createdReportPresentations: false,
							errors: action.errors
						}
					: pm
		)
	});
};

const startQueryUnsentReports = (state, action) => {
	return updateObject(state, {
		planningMonths: state.planningMonths.map(
			(pm, i) =>
				i === action.monthIndex
					? {
							...pm,
							isQueryingUnsentReports: true
						}
					: pm
		)
	});
};
const queryUnsentReportsSuccess = (state, action) => {
	return updateObject(state, {
		planningMonths: state.planningMonths.map(
			(pm, i) =>
				i === action.monthIndex
					? {
							...pm,
							isQueryingUnsentReports: false,
							didQueryUnsentReports: true
						}
					: pm
		)
	});
};
const queryUnsentReportsFailure = (state, action) => {
	return updateObject(state, {
		planningMonths: state.planningMonths.map(
			(pm, i) =>
				i === action.monthIndex
					? {
							...pm,
							isQueryingUnsentReports: false,
							didQueryUnsentReports: false,
							errors: action.errors
						}
					: pm
		)
	});
};

const startFetchArticleHistorySets = (state, action) => {
	return updateObject(state, {
		articleHistorySets: {
			...state.articleHistorySets,
			isLoading: true,
			isLoaded: false,
			errors: null
		}
	});
};
const fetchArticleHistorySetsSuccess = (state, action) => {
	return updateObject(state, {
		articleHistorySets: {
			...state.articleHistorySets,
			data: action.articleHistorySets,
			isLoading: false,
			isLoaded: true
		}
	});
};
const fetchArticleHistorySetsFailure = (state, action) => {
	return updateObject(state, {
		articleHistorySets: {
			...state.articleHistorySets,
			isLoading: false,
			isLoaded: false,
			errors: action.errors
		}
	});
};

const startArticleHistorySetRefresh = (state, action) => {
	return updateObject(state, {
		articleHistorySets: {
			...state.articleHistorySets,
			isRefreshing: action.articleHistorySetStatus,
		}
	})
}
const articleHistorySetRefreshSuccess = (state, action) => {
	return updateObject(state, {
		articleHistorySets: {
			...state.articleHistorySets,
			data: state.articleHistorySets.data.map((set) => set.id === action.articleHistorySet.id ? action.articleHistorySet : set),
			isRefreshing: null,
		}
	})
}
const articleHistorySetRefreshFailure = (state, action) => {
	return updateObject(state, {
		articleHistorySets: {
			...state.articleHistorySets,
			isRefreshing: null,
			errors: action.errors
		}
	})
}

// Generic article fetch
export const startFetchArticles = (state, action) => {
	return updateObject(state, {
		contentArticles: [],
		isLoadingArticles: true,
		articlesLoaded: false,
		errors: null
	});
};
export const fetchArticlesSuccess = (state, action) => {
	return updateObject(state, {
		contentArticles: state.contentArticles.concat(action.articles),
		isLoadingArticles: false,
		articlesLoaded: true,
		errors: null
	});
};
export const fetchArticlesFailure = (state, action) => {
	return updateObject(state, {
		isLoadingArticles: false,
		articlesLoaded: false,
		errors: action.errors
	});
};

// Re-assign Articles
export const startReAssignArticles = (state, action) => {
	return updateObject(state, {
		reAssigningInProgress: true,
		didReAssign: false,
		errors: null
	})
}
export const reAssignArticlesSuccess = (state, action) => {
	return updateObject(state, {
		reAssigningInProgress: false,
		didReAssign: true,
		reAssignMessage: action.resp,
		errors: null
	})
}
export const reAssignArticlesFailure = (state, action) => {
	return updateObject(state, {
		reAssigningInProgress: false,
		didReAssign: false,
		errors: action.errors
	})
}

export const startFetchArticleChangelog = (state, action) => {
	return updateObject(state, {
		isFetchingChangelog: true,
		fetchedChangelog: false
	});
};

export const fetchArticleChangelogSuccess = (state, action) => {
	// let articleIndex = state.contentArticles.findIndex((x) => x.id === action.articleId);
	return updateObject(state, {
		// contentArticles: state.contentArticles.map(
		// 	(article, i) => (i === articleIndex ? { ...article, changelog: action.articleChangelog } : article)
		// ),
		changelog: action.articleChangelog,
		errors: null,
		isFetchingChangelog: false,
		fetchedChangelog: true
	});
};

export const fetchArticleChangelogFailure = (state, action) => {
	return updateObject(state, {
		isFetchingChangelog: false,
		fetchedChangelog: false,
		errors: action.errors
	});
};

// export const startFetchArticles = (state, action) => {
// 	return updateObject(state, {
// 		articles: {
// 			...state.articles,
// 			isLoading: true,
// 			isLoaded: false,
// 		}
// 	})
// }
// export const fetchArticlesSuccess = (state, action) => {
// 	return updateObject(state, {
// 		articles: {
// 			...state.articles,
// 			data: action.articles,
// 			isLoading: false,
// 			isLoaded: true,
// 		}
// 	})
// }
// export const fetchArticlesFailure = (state, action) => {
// 	return updateObject(state, {
// 		articles: {
// 			...state.articles,
// 			isLoading: false,
// 			isLoaded: false,
// 			errors: action.errors,
// 		}
// 	})
// }

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.MODIFY_FILTERS:
			return modifyFilters(state, action);
		case actionTypes.START_ARTICLE_FETCH:
			return startArticleFetch(state, action);
		case actionTypes.START_TEMPLATE_FETCH:
			return startTemplateFetch(state, action);
		case actionTypes.START_KEYWORD_FETCH:
			return startKeywordFetch(state, action);
		case actionTypes.START_PLANNING_MONTH_FETCH:
			return startPlanningMonthFetch(state, action);
		case actionTypes.START_ADDITIONAL_PLANNING_MONTH_FETCH:
			return startAdditionalPlanningMonthFetch(state, action);
		case actionTypes.START_CONTENT_PROJECT_FETCH:
			return startContentProjectFetch(state, action);
		case actionTypes.IS_ADDING_ARTICLE:
			return isAddingArticle(state, action);
		case actionTypes.CANCEL_ADDING_ARTICLE:
			return cancelAddingArticle(state, action);
		case actionTypes.IS_ADDING_TEMPLATE:
			return isAddingTemplate(state, action);
		case actionTypes.CANCEL_ADDING_TEMPLATE:
			return cancelAddingTemplate(state, action);
		case actionTypes.IS_ADDING_MONTH:
			return isAddingMonth(state, action);
		case actionTypes.CANCEL_ADDING_MONTH:
			return cancelAddingMonth(state, action);
		case actionTypes.IS_ADDING_PROJECT:
			return isAddingProject(state, action);
		case actionTypes.CANCEL_ADDING_PROJECT:
			return cancelAddingProject(state, action);
		case actionTypes.ADD_CONTENT_ARTICLE_SUCCESS:
			return addContentArticleSuccess(state, action);
		case actionTypes.UPDATE_CONTENT_ARTICLE_SUCCESS:
			return updateContentArticleSuccess(state, action);
		case actionTypes.UPDATE_BULK_CONTENT_ARTICLE_SUCCESS:
			return updateBulkContentArticleSuccess(state, action);
		case actionTypes.DELETE_CONTENT_ARTICLE_SUCCESS:
			return deleteContentArticleSuccess(state, action);
		case actionTypes.ADD_ARTICLE_TEMPLATE_SUCCESS:
			return addArticleTemplateSuccess(state, action);
		case actionTypes.UPDATE_ARTICLE_TEMPLATE_SUCCESS:
			return updateArticleTemplateSuccess(state, action);
		case actionTypes.DELETE_ARTICLE_TEMPLATE_SUCCESS:
			return deleteArticleTemplateSuccess(state, action);
		case actionTypes.ADD_PLANNING_MONTH_SUCCESS:
			return addPlanningMonthSuccess(state, action);
		case actionTypes.UPDATE_PLANNING_MONTH_SUCCESS:
			return updatePlanningMonthSuccess(state, action);
		case actionTypes.PLANNING_MONTH_FAILURE:
			return planningMonthFailure(state, action);
		case actionTypes.ADD_CONTENT_PROJECT_SUCCESS:
			return addContentProjectSuccess(state, action);
		case actionTypes.UPDATE_CONTENT_PROJECT_SUCCESS:
			return updateContentProjectSuccess(state, action);
		case actionTypes.CONTENT_PROJECT_FAILURE:
			return contentProjectFailure(state, action);
		case actionTypes.ADD_CONTENT_KEYWORD_SUCCESS:
			return addContentKeywordSuccess(state, action);
		case actionTypes.UPDATE_CONTENT_KEYWORD_SUCCESS:
			return updateContentKeywordSuccess(state, action);
		case actionTypes.DELETE_CONTENT_KEYWORD_SUCCESS:
			return deleteContentKeywordSuccess(state, action);
		case actionTypes.CONTENT_KEYWORD_FAILURE:
			return contentKeywordFailure(state, action);
		case actionTypes.SET_CONTENT_ARTICLES:
			return setContentArticles(state, action);
		case actionTypes.SET_PROJECT_ARTICLES:
			return setProjectArticles(state, action);
		case actionTypes.SET_ORGANIZATION_ARTICLES:
			return setOrganizationArticles(state, action);
		case actionTypes.SET_ARTICLE_TEMPLATES:
			return setArticleTemplates(state, action);
		case actionTypes.FETCH_CONTENT_ARTICLES_FAILED:
			return fetchContentArticlesFailed(state, action);
		case actionTypes.START_CONTENT_DATA_FETCH:
			return startContentDataFetch(state, action);
		case actionTypes.SET_CONTENT_TYPES:
			return setContentTypes(state, action);
		case actionTypes.SET_CONTENT_STATUSES:
			return setContentStatuses(state, action);
		case actionTypes.SET_CONTENT_CHANNELS:
			return setContentChannels(state, action);
		case actionTypes.SET_CONTENT_KEYWORDS:
			return setContentKeywords(state, action);
		case actionTypes.SET_PLANNING_MONTHS:
			return setPlanningMonths(state, action);
		case actionTypes.SET_ADDITIONAL_PLANNING_MONTHS:
			return setAdditionalPlanningMonths(state, action);
		case actionTypes.SET_CONTENT_PROJECTS:
			return setContentProjects(state, action);
		case actionTypes.SET_CONTENT_TAGS:
			return setContentTags(state, action);
		case actionTypes.SET_WRITERS:
			return setWriters(state, action);
		case actionTypes.SET_CONTENT_STAFF:
			return setContentStaff(state, action);
		case actionTypes.START_FETCH_APPROVAL_STAFF:
			return startFetchApprovalStaff(state, action);
		case actionTypes.SET_APPROVAL_STAFF:
			return setApprovalStaff(state, action);
		case actionTypes.CONTENT_DATA_FAILURE:
			return contentDataFailure(state, action);
		case actionTypes.CONTENT_ARTICLE_FAILURE:
			return contentArticleFailure(state, action);
		case actionTypes.ARTICLE_TEMPLATE_FAILURE:
			return articleTemplateFailure(state, action);
		case actionTypes.SET_CONTENT_COMMENTS:
			return setContentComments(state, action);
		case actionTypes.ADD_CONTENT_COMMENT_SUCCESS:
			return addContentCommentSuccess(state, action);
		case actionTypes.UPDATE_CONTENT_COMMENT_SUCCESS:
			return updateContentCommentSuccess(state, action);
		case actionTypes.DELETE_CONTENT_COMMENT_SUCCESS:
			return deleteContentCommentSuccess(state, action);
		case actionTypes.SET_FEEDBACK:
			return setFeedback(state, action);
		case actionTypes.ADD_FEEDBACK_SUCCESS:
			return addFeedbackSuccess(state, action);
		case actionTypes.UPDATE_FEEDBACK_SUCCESS:
			return updateFeedbackSuccess(state, action);
		case actionTypes.DELETE_FEEDBACK_SUCCESS:
			return deleteFeedbackSuccess(state, action);
		case actionTypes.START_CREATE_GOOGLE_DOC:
			return startCreateGoogleDoc(state, action);
		case actionTypes.CREATE_GOOGLE_DOC_SUCCESS:
			return createGoogleDocSuccess(state, action);
		case actionTypes.GOOGLE_DOC_FAILURE:
			return googleDocFailure(state, action);
		case actionTypes.UPDATE_ARTICLE_STATUS_SUCCESS:
			return updateArticleStatusSuccess(state, action);
		case actionTypes.UPDATE_ARTICLE_STATUS_FAILURE:
			return updateArticleStatusFailure(state, action);
		case actionTypes.START_CREATE_MONTHLY_REPORTS:
			return startCreateMonthlyReports(state, action);
		case actionTypes.CREATE_MONTHLY_REPORTS_SUCCESS:
			return createMonthlyReportsSuccess(state, action);
		case actionTypes.CREATE_MONTHLY_REPORTS_FAILURE:
			return createMonthlyReportsFailure(state, action);
		case actionTypes.START_VALIDATE_MONTHLY_REPORT_DATA:
			return startValidateMonthlyReportData(state, action);
		case actionTypes.VALIDATE_MONTHLY_REPORT_DATA_SUCCESS:
			return validateMonthlyReportDataSuccess(state, action);
		case actionTypes.VALIDATE_MONTHLY_REPORT_DATA_FAILURE:
			return validateMonthlyReportDataFailure(state, action);
		case actionTypes.START_CREATE_MONTHLY_REPORT_PRESENTATIONS:
			return startCreateMonthlyReportPresentations(state, action);
		case actionTypes.CREATE_MONTHLY_REPORT_PRESENTATIONS_SUCCESS:
			return createMonthlyReportPresentationsSuccess(state, action);
		case actionTypes.CREATE_MONTHLY_REPORT_PRESENTATIONS_FAILURE:
			return createMonthlyReportPresentationsFailure(state, action);
		case actionTypes.START_QUERY_UNSENT_REPORTS:
			return startQueryUnsentReports(state, action);
		case actionTypes.QUERY_UNSENT_REPORTS_SUCCESS:
			return queryUnsentReportsSuccess(state, action);
		case actionTypes.QUERY_UNSENT_REPORTS_FAILURE:
			return queryUnsentReportsFailure(state, action);
		case actionTypes.START_FETCH_ARTICLE_HISTORY_SETS:
			return startFetchArticleHistorySets(state, action);
		case actionTypes.FETCH_ARTICLE_HISTORY_SETS_SUCCESS:
			return fetchArticleHistorySetsSuccess(state, action);
		case actionTypes.FETCH_ARTICLE_HISTORY_SETS_FAILURE:
			return fetchArticleHistorySetsFailure(state, action);
		case actionTypes.START_ARTICLE_HISTORY_SET_REFRESH:
			return startArticleHistorySetRefresh(state, action);
		case actionTypes.ARTICLE_HISTORY_SET_REFRESH_SUCCESS:
			return articleHistorySetRefreshSuccess(state, action);
		case actionTypes.ARTICLE_HISTORY_SET_REFRESH_FAILURE:
			return articleHistorySetRefreshFailure(state, action);
		case actionTypes.START_FETCH_ARTICLES:
			return startFetchArticles(state, action);
		case actionTypes.FETCH_ARTICLES_SUCCESS:
			return fetchArticlesSuccess(state, action);
		case actionTypes.FETCH_ARTICLES_FAILURE:
			return fetchArticlesFailure(state, action);
		case actionTypes.START_REASSIGN_ARTICLES:
			return startReAssignArticles(state, action);
		case actionTypes.REASSIGN_ARTICLES_SUCCESS:
			return reAssignArticlesSuccess(state, action);
		case actionTypes.REASSIGN_ARTICLES_FAILURE:
			return reAssignArticlesFailure(state, action);
		case actionTypes.START_FETCH_ARTICLE_CHANGELOG:
			return startFetchArticleChangelog(state, action);
		case actionTypes.FETCH_ARTICLE_CHANGELOG_SUCCESS:
			return fetchArticleChangelogSuccess(state, action);
		case actionTypes.FETCH_ARTICLE_CHANGELOG_FAILURE:
			return fetchArticleChangelogFailure(state, action);
		case actionTypes.ADD_MESSAGE:
			return addMessage(state, action);
		case actionTypes.REMOVE_MESSAGE:
			return removeMessage(state, action);
		default:
			return state;
	}
};

export default reducer;
